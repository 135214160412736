
import { generateId, showError } from '@/util-functions/misc-firestore-utils';
import Vue from 'vue';
import VueGoogleAutocomplete from 'vue-google-autocomplete';

export default Vue.extend({
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    onGetAddressData: {
      type: Function,
      default: () => undefined,
    },
    onAddressInputChange: {
      type: Function,
      default: () => undefined,
    },
    id: {
      type: String,
      default: generateId(),
    },
    formattedAddress: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data(): {
    hasAddress: boolean;
  } {
    return {
      hasAddress: false,
    };
  },
  methods: {
    onGoogleAutocompleteError(error: any) {
      showError(error?.message);
    },
    onInputChange(input: string) {
      this.hasAddress = false;
      this.onAddressInputChange(input);
    },
    onGetAddressDataLocal(addressData: any, place: any) {
      this.onGetAddressData(addressData, place);
      Vue.nextTick(() => {
        this.hasAddress = Boolean(addressData);
      });
    },
    clearAddress() {
      this.onGetAddressData('clearAddress');
    },
  },
});
