
import Vue from 'vue';
import { modeFormMixin } from '@/mixins';
import { getEmptyDetailedAddress, getEmptySitchEvent } from '@/constants';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { handleImages } from '@/util-functions/image-utils';
import SitchAddress from '@/components/custom-ui-components/SitchAddress.vue';
import { getLocationAddress } from '@/util-functions/misc-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { t } from '@/util-functions/language-utils';
import { saveMode } from '@/util-functions/firestore-mode-utils';

export default Vue.extend({
  mixins: [modeFormMixin],
  components: {
    SitchAddress,
  },
  data(): {
    startTimePickers: Record<string, unknown>;
    datePickers: Record<string, unknown>;
    imageSizes: ImageSizes;
    modeForm: Omit<EventsMode, keyof Mode>;
  } {
    return {
      startTimePickers: {},
      datePickers: {},
      imageSizes: { small: 200, large: 1080 },
      modeForm: {} as Omit<EventsMode, keyof Mode>,
    };
  },
  methods: {
    onGetAddressData(event: SitchEvent, addressData: any, place: any) {
      event.locationAddress = getLocationAddress(addressData, place, event);
    },
    onAddressInputChange(event: SitchEvent, input: string) {
      if (!input) {
        event.locationAddress = getEmptyDetailedAddress();
      }
    },
    onAddEvent() {
      // This limit is to prevent nefarious use.
      const EVENT_MAX = 100;
      if (this.modeForm.events.length > EVENT_MAX) {
        showError(t?.eventItemMax.supplant([EVENT_MAX]));
        return;
      }
      this.modeForm.events.push(getEmptySitchEvent());
    },
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      const mixinMethods = this as any as ModeMixinMethods;
      const postDeletionPreviewArray: Event[] = mixinMethods.postDeletionPreviewArray(this.modeForm.events);

      if (!postDeletionPreviewArray.length) {
        showError(t.mustHaveAtleastOneItem);
        return;
      }

      mixinMethods
        .pruneAndCommitDeletionsJustBeforeCreatingCombinedModeData()
        .then(() => {
          const promiseArray: Promise<any>[] = [];
          const combinedModeData = getCombinedModeFormData(this) as EventsMode;
          const storagePath = `userFiles/${this.$store.state.userId}/${combinedModeData.docId}`;

          // Cycle through every gallery item in the form.
          combinedModeData.events.forEach((item) => {
            handleImages(
              item,
              [
                {
                  key: 'images',
                  imageSizes: this.imageSizes,
                },
              ],
              promiseArray,
              storagePath
            );
          });

          Promise.all(promiseArray).then(() => {
            saveMode({ ...combinedModeData });
          });
        })
        .catch(() => {
          showError(t.somethingWentWrongWhenDeletingAnItem);
        });
    },
  },
});
