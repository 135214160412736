
import Vue from 'vue';
import { modeFormMixin } from '@/mixins';
import SitchAddress from '@/components/custom-ui-components/SitchAddress.vue';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { getEmptyDetailedAddress } from '@/constants';
import { getLocationAddress } from '@/util-functions/misc-utils';
import { saveMode } from '@/util-functions/firestore-mode-utils';

export default Vue.extend({
  mixins: [modeFormMixin],
  components: {
    SitchAddress,
  },
  data(): {
    modeForm: Omit<ReturnToMode, keyof Mode>;
  } {
    return {
      modeForm: {} as Omit<ReturnToMode, keyof Mode>,
    };
  },
  methods: {
    onGetAddressData(addressData: any, place: any) {
      this.modeForm.locationAddress = getLocationAddress(addressData, place, this.modeForm);
    },
    onAddressInputChange(input: string) {
      if (!input) {
        this.modeForm.locationAddress = getEmptyDetailedAddress();
      }
    },
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }
      const combinedModeData = getCombinedModeFormData(this);
      saveMode({ ...combinedModeData });
    },
  },
});
