
import Vue from 'vue';
import { modeFormMixin } from '@/mixins';
import { eCustomFieldTypes } from '@/enums';
import { getEmptyCustomField } from '@/constants';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import SitchEmailSelect from './custom-ui-components/SitchEmailSelect.vue';
import SitchTextEditor from '@/components/custom-ui-components/SitchTextEditor.vue';
import { draggableOptions } from '@/constants';
import { saveMode } from '@/util-functions/firestore-mode-utils';
import { t } from '@/util-functions/language-utils';
import { generateId, showError } from '@/util-functions/misc-firestore-utils';
import { isFeatureEnabled, getParameterByName } from '@/util-functions/misc-utils';
import { showNotice } from '@/util-functions/notice-utils';

export default Vue.extend({
  components: { SitchEmailSelect, SitchTextEditor },
  mixins: [modeFormMixin],
  data(): {
    modeForm: Omit<CustomFormMode, keyof Mode>;
    draggableOptions: Record<string, unknown>;
    customFieldTypes: TextValue[];
    currEnteredChoiceText: string;
    eCustomFieldTypes: typeof eCustomFieldTypes;
  } {
    const customFieldTypes = [
      {
        text: t.shortText,
        value: eCustomFieldTypes.shortText,
      },
      {
        text: t.longText,
        value: eCustomFieldTypes.longText,
      },
      {
        text: t.number,
        value: eCustomFieldTypes.number,
      },
      {
        text: t.date,
        value: eCustomFieldTypes.date,
      },
      {
        text: t.time,
        value: eCustomFieldTypes.time,
      },
      {
        text: t.dateTime,
        value: eCustomFieldTypes.dateTime,
      },
      {
        text: t.checkbox,
        value: eCustomFieldTypes.checkbox,
      },
      {
        text: t.review,
        value: eCustomFieldTypes.outOf5,
      },
      {
        text: t.multipleChoice,
        value: eCustomFieldTypes.multipleChoice,
      },
      {
        text: t.url,
        value: eCustomFieldTypes.url,
      },
      {
        text: t.email,
        value: eCustomFieldTypes.email,
      },
      {
        text: t.phoneNumber,
        value: eCustomFieldTypes.phoneNumber,
      },
      {
        text: t.address,
        value: eCustomFieldTypes.address,
      },
      {
        text: t.signature,
        value: eCustomFieldTypes.signature,
      },
    ];
    if (isFeatureEnabled(this.$store.state.featureFlags.fileUploadsInCustomForms)) {
      customFieldTypes.push({
        text: t.fileUpload,
        value: eCustomFieldTypes.fileUpload,
      });
    }
    return {
      modeForm: {} as Omit<CustomFormMode, keyof Mode>,
      draggableOptions,
      customFieldTypes,
      currEnteredChoiceText: '',
      eCustomFieldTypes,
    };
  },
  methods: {
    onNewModeFormPopulationDone() {
      const customFormType = getParameterByName('cft');
      this.modeForm.customFields = [];
      switch (customFormType) {
        case 'contact':
          this.modeForm.requireName = true;
          this.modeForm.requireEmail = true;
          this.modeForm.requirePhoneNumber = false;

          this.modeForm.customFields.push({
            id: generateId(),
            name: t.message,
            type: eCustomFieldTypes.longText,
            description: '',
            isRequired: true,
            choices: [],
            isHidden: false,
          });
          return;
        case 'review':
          this.modeForm.requireName = false;
          this.modeForm.requireEmail = false;
          this.modeForm.requirePhoneNumber = false;
          this.modeForm.customFields.push({
            id: generateId(),
            name: t.howDidWeDo,
            type: eCustomFieldTypes.outOf5,
            description: '',
            isRequired: true,
            choices: [],
            isHidden: false,
          });
          this.modeForm.customFields.push({
            id: generateId(),
            name: t.generalFeedback,
            type: eCustomFieldTypes.longText,
            description: '',
            isRequired: false,
            choices: [],
            isHidden: false,
          });
          return;
        case 'guestbook':
          this.modeForm.requireName = true;
          this.modeForm.requireEmail = true;
          this.modeForm.requirePhoneNumber = false;
          return;
      }
    },
    onAddChoice(field: CustomField) {
      if (!this.currEnteredChoiceText) {
        showError(t.mustEnterAChoice);
        return;
      }
      if (field.choices.includes(this.currEnteredChoiceText)) {
        showError(t.choicesMustBeUnique);
        return;
      }
      const CHOICE_MAX = 100;
      if (field.choices.length > CHOICE_MAX) {
        showError(t?.genericItemMax.supplant([CHOICE_MAX]));
        return;
      }
      field.choices.push(this.currEnteredChoiceText);
      this.currEnteredChoiceText = '';
    },
    onRemoveChoice(field: CustomField, choiceToRemove: string) {
      field.choices = field.choices.filter((choice) => choice !== choiceToRemove);
      this.$forceUpdate();
    },
    copyField(customFieldToCopy: CustomField) {
      const CUSTOM_FIELD_MAX = 100;
      if (this.modeForm.customFields.length > CUSTOM_FIELD_MAX) {
        showError(t?.customFieldItemMax.supplant([CUSTOM_FIELD_MAX]));
        return;
      }

      this.modeForm.customFields.push({
        ...customFieldToCopy,
        id: generateId(),
      });
      showNotice(t.fieldCopiedToTheEndOfTheList);
    },
    onAddCustomField() {
      const CUSTOM_FIELD_MAX = 100;
      if (this.modeForm.customFields.length > CUSTOM_FIELD_MAX) {
        showError(t?.customFieldItemMax.supplant([CUSTOM_FIELD_MAX]));
        return;
      }
      this.modeForm.customFields.push(getEmptyCustomField());
    },
    onAddTextField() {
      const CUSTOM_FIELD_MAX = 100;
      if (this.modeForm.customFields.length > CUSTOM_FIELD_MAX) {
        showError(t?.customFieldItemMax.supplant([CUSTOM_FIELD_MAX]));
        return;
      }
      this.modeForm.customFields.push({ ...getEmptyCustomField(), name: t.descriptiveText, type: eCustomFieldTypes.nonInputTextDescription, isRequired: false });
    },
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      const mixinMethods = this as any as ModeMixinMethods;
      const postDeletionPreviewArray: CustomField[] = mixinMethods.postDeletionPreviewArray(this.modeForm.customFields);

      const hasCustomRequiredField = postDeletionPreviewArray.some((field) => {
        return field.isRequired;
      });

      if (!this.modeForm.requireName && !this.modeForm.requirePhoneNumber && !this.modeForm.requireEmail && !hasCustomRequiredField) {
        showError(t?.yourFormMustHaveAtleastOneRequiredField);
        return;
      }

      mixinMethods
        .pruneAndCommitDeletionsJustBeforeCreatingCombinedModeData()
        .then(() => {
          const combinedModeData = getCombinedModeFormData(this) as CustomFormMode;

          saveMode({ ...combinedModeData });
        })
        .catch(() => {
          showError(t.somethingWentWrongWhenDeletingAnItem);
        });
    },
  },
});
