
import Vue from 'vue';
import { modeFormMixin } from '@/mixins';
import { addHttp } from '@/util-functions/misc-utils';

export default Vue.extend({
  mixins: [modeFormMixin],
  data(): {
    showIframePreview: boolean;
    iframePreviewUrl: string;
    modeForm: Omit<UrlRedirectMode, keyof Mode>;
  } {
    return {
      showIframePreview: false,
      iframePreviewUrl: '',
      modeForm: {} as Omit<UrlRedirectMode, keyof Mode>,
    };
  },
  methods: {
    addHttpLocal(url: string): string {
      return addHttp(url, true);
    },
    onIframePreview() {
      this.iframePreviewUrl = this.modeForm.redirectUrl;
      this.showIframePreview = true;
    },
  },
});
