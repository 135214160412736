
import Vue from 'vue';
import SitchTextEditor from '@/components/custom-ui-components/SitchTextEditor.vue';
import SitchImageUpload from '@/components/custom-ui-components/SitchImageUpload.vue';
import { modeFormMixin } from '@/mixins';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { handleImages } from '@/util-functions/image-utils';
import { saveMode } from '@/util-functions/firestore-mode-utils';

export default Vue.extend({
  mixins: [modeFormMixin],
  components: {
    SitchTextEditor,
    SitchImageUpload,
  },
  data(): {
    modeForm: Omit<ArticleMode, keyof Mode>;
    imageSizes: ImageSizes;
  } {
    return {
      modeForm: {} as Omit<ArticleMode, keyof Mode>,
      imageSizes: { small: 200, large: 1080 },
    };
  },
  methods: {
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      const promiseArray: Promise<any>[] = [];
      const combinedModeData = getCombinedModeFormData(this) as ArticleMode;
      const storagePath = `userFiles/${this.$store.state.userId}/${combinedModeData.docId}`;

      handleImages(
        combinedModeData,
        [
          {
            key: 'images',
            imageSizes: this.imageSizes,
          },
        ],
        promiseArray,
        storagePath
      );

      Promise.all(promiseArray).then(() => {
        saveMode({ ...combinedModeData });
      });
    },
  },
});
