export const canadianRates = {
  ca_ab: { code:'ca_ab', taxRate: Math.round(0.05000*100*100)/100, name: 'Alberta' },
  ca_bc: { code:'ca_bc', taxRate: Math.round(0.05000*100*100)/100, name: 'British Columbia' }, // Only charge 7% PST if the business advertises to BC residents.
  ca_mb: { code:'ca_mb', taxRate: Math.round(0.12000*100*100)/100, name: 'Manitoba' }, // 7% PST always charged.
  ca_nb: { code:'ca_nb', taxRate: Math.round(0.15000*100*100)/100, name: 'New Brunswick' },
  ca_nl: { code:'ca_nl', taxRate: Math.round(0.15000*100*100)/100, name: 'Newfoundland and Labrador' },
  ca_ns: { code:'ca_ns', taxRate: Math.round(0.15000*100*100)/100, name: 'Nova Scotia' },
  ca_nt: { code:'ca_nt', taxRate: Math.round(0.05000*100*100)/100, name: 'Northwest Territories' },
  ca_nu: { code:'ca_nu', taxRate: Math.round(0.05000*100*100)/100, name: 'Nunavut' },
  ca_on: { code:'ca_on', taxRate: Math.round(0.13000*100*100)/100, name: 'Ontario' },
  ca_pe: { code:'ca_pe', taxRate: Math.round(0.15000*100*100)/100, name: 'Prince Edward Island' },
  ca_qc: { code:'ca_qc', taxRate: Math.round(0.14975*100*100)/100, name: 'Québec' }, // QST not requited for business not in quebec.
  ca_sk: { code:'ca_sk', taxRate: Math.round(0.11000*100*100)/100, name: 'Saskatchewan' }, // 6% PST always charged.
  ca_yt: { code:'ca_yt', taxRate: Math.round(0.05000*100*100)/100, name: 'Yukon' },
}

export const usRates = {
  us_al: { code:'us_al', taxRate: Math.round(0.04000*100*100)/100, name: 'Alabama' },
  us_ak: { code:'us_ak', taxRate: Math.round(0.00000*100*100)/100, name: 'Alaska' },
  us_az: { code:'us_az', taxRate: Math.round(0.05600*100*100)/100, name: 'Arizona' },
  us_ar: { code:'us_ar', taxRate: Math.round(0.06500*100*100)/100, name: 'Arkansas' },
  us_ca: { code:'us_ca', taxRate: Math.round(0.07250*100*100)/100, name: 'California' },
  us_co: { code:'us_co', taxRate: Math.round(0.02900*100*100)/100, name: 'Colorado' },
  us_ct: { code:'us_ct', taxRate: Math.round(0.06350*100*100)/100, name: 'Connecticut' },
  us_de: { code:'us_de', taxRate: Math.round(0.00000*100*100)/100, name: 'Delaware' },
  us_dc: { code:'us_dc', taxRate: Math.round(0.06000*100*100)/100, name: 'District Of Columbia' },
  us_fl: { code:'us_fl', taxRate: Math.round(0.06000*100*100)/100, name: 'Florida' },
  us_ga: { code:'us_ga', taxRate: Math.round(0.04000*100*100)/100, name: 'Georgia' },
  us_hi: { code:'us_hi', taxRate: Math.round(0.04000*100*100)/100, name: 'Hawaii' },
  us_id: { code:'us_id', taxRate: Math.round(0.06000*100*100)/100, name: 'Idaho' },
  us_il: { code:'us_il', taxRate: Math.round(0.06250*100*100)/100, name: 'Illinois' },
  us_in: { code:'us_in', taxRate: Math.round(0.07000*100*100)/100, name: 'Indiana' },
  us_ia: { code:'us_ia', taxRate: Math.round(0.06000*100*100)/100, name: 'Iowa' },
  us_ks: { code:'us_ks', taxRate: Math.round(0.06500*100*100)/100, name: 'Kansas' },
  us_ky: { code:'us_ky', taxRate: Math.round(0.06000*100*100)/100, name: 'Kentucky' },
  us_la: { code:'us_la', taxRate: Math.round(0.04450*100*100)/100, name: 'Louisiana' },
  us_me: { code:'us_me', taxRate: Math.round(0.05500*100*100)/100, name: 'Maine' },
  us_md: { code:'us_md', taxRate: Math.round(0.06000*100*100)/100, name: 'Maryland' },
  us_ma: { code:'us_ma', taxRate: Math.round(0.06250*100*100)/100, name: 'Massachusetts' },
  us_mi: { code:'us_mi', taxRate: Math.round(0.06000*100*100)/100, name: 'Michigan' },
  us_mn: { code:'us_mn', taxRate: Math.round(0.06875*100*100)/100, name: 'Minnesota' },
  us_ms: { code:'us_ms', taxRate: Math.round(0.07000*100*100)/100, name: 'Mississippi' },
  us_mo: { code:'us_mo', taxRate: Math.round(0.04225*100*100)/100, name: 'Missouri' },
  us_mt: { code:'us_mt', taxRate: Math.round(0.00000*100*100)/100, name: 'Montana' },
  us_ne: { code:'us_ne', taxRate: Math.round(0.05500*100*100)/100, name: 'Nebraska' },
  us_nv: { code:'us_nv', taxRate: Math.round(0.06850*100*100)/100, name: 'Nevada' },
  us_nh: { code:'us_nh', taxRate: Math.round(0.00000*100*100)/100, name: 'New Hampshire' },
  us_nj: { code:'us_nj', taxRate: Math.round(0.06625*100*100)/100, name: 'New Jersey' },
  us_nm: { code:'us_nm', taxRate: Math.round(0.05125*100*100)/100, name: 'New Mexico' },
  us_ny: { code:'us_ny', taxRate: Math.round(0.04000*100*100)/100, name: 'New York' },
  us_nc: { code:'us_nc', taxRate: Math.round(0.04750*100*100)/100, name: 'North Carolina' },
  us_nd: { code:'us_nd', taxRate: Math.round(0.05000*100*100)/100, name: 'North Dakota' },
  us_oh: { code:'us_oh', taxRate: Math.round(0.05750*100*100)/100, name: 'Ohio' },
  us_ok: { code:'us_ok', taxRate: Math.round(0.04500*100*100)/100, name: 'Oklahoma' },
  us_or: { code:'us_or', taxRate: Math.round(0.00000*100*100)/100, name: 'Oregon' },
  us_pa: { code:'us_pa', taxRate: Math.round(0.06000*100*100)/100, name: 'Pennsylvania' },
  us_pr: { code:'us_pr', taxRate: Math.round(0.11500*100*100)/100, name: 'Puerto Rico' },
  us_ri: { code:'us_ri', taxRate: Math.round(0.07000*100*100)/100, name: 'Rhode Island' },
  us_sc: { code:'us_sc', taxRate: Math.round(0.06000*100*100)/100, name: 'South Carolina' },
  us_sd: { code:'us_sd', taxRate: Math.round(0.04500*100*100)/100, name: 'South Dakota' },
  us_tn: { code:'us_tn', taxRate: Math.round(0.07000*100*100)/100, name: 'Tennessee' },
  us_tx: { code:'us_tx', taxRate: Math.round(0.06250*100*100)/100, name: 'Texas' },
  us_ut: { code:'us_ut', taxRate: Math.round(0.04850*100*100)/100, name: 'Utah' },
  us_vt: { code:'us_vt', taxRate: Math.round(0.06000*100*100)/100, name: 'Vermont' },
  us_va: { code:'us_va', taxRate: Math.round(0.04300*100*100)/100, name: 'Virginia' },
  us_wa: { code:'us_wa', taxRate: Math.round(0.06500*100*100)/100, name: 'Washington' },
  us_wv: { code:'us_wv', taxRate: Math.round(0.06000*100*100)/100, name: 'West Virginia' },
  us_wi: { code:'us_wi', taxRate: Math.round(0.05000*100*100)/100, name: 'Wisconsin' },
  us_wy: { code:'us_wy', taxRate: Math.round(0.04000*100*100)/100, name: 'Wyoming' },
};
