
import Vue from 'vue';
import { VueEditor, Quill } from 'vue2-editor';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-module';
import ImageCompress from 'quill-image-compress';
import { editorOptions } from '@/constants';
import { showError } from '@/util-functions/misc-firestore-utils';
import { t } from '@/util-functions/language-utils';

Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);

export default Vue.extend({
  components: {
    VueEditor,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    defaultHeight: {
      type: Number,
      default: 400
    }
  },
  data(): {
    editorOptions: any;
    content: string;
    height: number;
  } {
    return {
      editorOptions: editorOptions,
      content: '',
      height: this.defaultHeight,
    };
  },
  watch: {
    value() {
      this.onLoad();
    },
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.content = this.value;
    },
    onChange() {
      if (this.content.length < 1_000_000 ) {
        this.$emit('input', this.content);
      } else {
        showError(t.contentTooLarge)
      }
    },
    sizeDown() {
      if (this.height > 100) {
        this.height -= 100;
      }
    },
    sizeUp() {
      if (this.height < 1000) {
        this.height += 100;
      }
    },
  },
});
