
import { endpoints } from '@/constants';
import languagesArray from '@/languages';
import { modeFormMixin } from '@/mixins';
import { saveMode } from '@/util-functions/firestore-mode-utils';
import { currFirestore, fbAuth, standardApiFetch } from '@/util-functions/initialization-utils';
import { t } from '@/util-functions/language-utils';
import { showError, clearChat } from '@/util-functions/misc-firestore-utils';
import { getCombinedModeFormData, modeFormsAreValid } from '@/util-functions/mode-form-utils';
import { showSuccess, showConfirmation } from '@/util-functions/notice-utils';
import { collection, deleteDoc, deleteField, doc, getDoc, getDocs, query, Timestamp, updateDoc, where, writeBatch } from 'firebase/firestore';
import Vue from 'vue';

interface ChatUser {
  id: string;
  name: string;
  locale: string;
  dateJoined: number;
}

interface Chat {
  docId: string;
  modeLinkId: string;
  chatRoomOwnerUserId: string;
  lastJoinAt: Timestamp;
  lastChatUserToJoinId: string;
  lastRejoinAt: Timestamp;
  lastChatUserToRejoinId: string;
  lastLeaveAt: Timestamp;
  lastChatUserToLeaveName: string;
  hasAutoTranslate: boolean;
  maximumParticipants: number;
  dateUpdated: Timestamp;
  dateCreated: Timestamp;
  webPushTokens: string[];
  participants: {
    [userId: string]: ChatUser;
  };
}

export default Vue.extend({
  mixins: [modeFormMixin],
  data(): {
    modeForm: Omit<ChatMode, keyof Mode>;
    languagesArray: TextValue[];
    modeExists: boolean;
    maxParticipantsOptions: TextValue[];
    currentChatRoom: Chat | null;
    selectedParticipantId: string;
  } {
    return {
      modeForm: {} as Omit<ChatMode, keyof Mode>,
      languagesArray: languagesArray,
      modeExists: false,
      maxParticipantsOptions: [
        { text: '2', value: 2 },
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '50', value: 50 },
      ],
      currentChatRoom: null,
      selectedParticipantId: '',
    };
  },
  computed: {
    currentParticipants(): TextValue[] {
      if (!this.currentChatRoom) {
        return [];
      }
      return Object.values(this.currentChatRoom.participants).map((chatUser) => {
        return {
          text: chatUser.name,
          value: chatUser.id,
        };
      });
    },
  },
  methods: {
    onFormPopulatedForEdit() {
      this.modeExists = Boolean(this.$parent?.$data.currModeForEdit);
      if (this.modeExists) {
        const chatRoomDoc = doc(currFirestore, 'chatRooms', this.$data.genericModeDataForm.docId);
        getDoc(chatRoomDoc).then((chatRoomDocSnap) => {
          if (chatRoomDocSnap.exists()) {
            this.currentChatRoom = chatRoomDocSnap.data() as Chat;
          }
        });
      }
    },
    removeSelectedParticipant() {
      if (!this.selectedParticipantId || !this.currentChatRoom) {
        showError(`Something went wrong.`);
        return;
      }

      const selectedPariticpant = this.currentChatRoom.participants[this.selectedParticipantId];
      if (!selectedPariticpant) {
        showError(`Could not find the participant.`);
        return;
      }
      const chatRoomDoc = doc(currFirestore, 'chatRooms', this.$data.genericModeDataForm.docId);
      const chatUserSecurityDocsQuery = query(collection(currFirestore, chatRoomDoc.path, 'securityKeys'), where('userId', '==', this.selectedParticipantId));

      getDocs(chatUserSecurityDocsQuery)
        .then((querySnap) => {
          if (querySnap.empty) {
            showError(`Cannot find security key for the user`);
            return;
          }
          const batch = writeBatch(currFirestore);

          querySnap.forEach((docSnap) => {
            // Should just be one doc.
            batch.delete(docSnap.ref);
          });

          batch.update(chatRoomDoc, {
            lastLeaveAt: Timestamp.fromMillis(Date.now()),
            lastChatUserToLeaveName: selectedPariticpant.name,
            [`participants.${this.selectedParticipantId}`]: deleteField(),
            dateUpdated: Timestamp.fromMillis(Date.now()),
          });

          batch
            .commit()
            .then(() => {
              showSuccess(t.participantWasRemoved);
              Vue.delete(this.currentChatRoom?.participants as any, this.selectedParticipantId);
              this.$forceUpdate();
            })
            .catch((error) => {
              showError(`Could not remove the participant.`, error, true);
            });
        })
        .catch((error) => {
          showError(`Could not get security key doc.`, error, true);
        });
    },
    clearChat() {
      showConfirmation(t?.confirmChatClear, () => {
        clearChat(this.$data.genericModeDataForm.docId).then(() => {
          if (this.currentChatRoom) {
            this.currentChatRoom.participants = {};
          }
          this.$forceUpdate();
        });
      });
    },
    resetChat() {
      if (!modeFormsAreValid(this)) {
        return;
      }
      showConfirmation(t?.confirmLinkReset, () => {
        const chatRoomDoc = doc(currFirestore, 'chatRooms', this.$data.genericModeDataForm.docId);
        getDoc(chatRoomDoc)
          .then((chatRoomDocSnap) => {
            if (chatRoomDocSnap.exists()) {
              const currUser = fbAuth.currentUser;

              if (!currUser) {
                showError(`No logged in user`);
                return;
              }

              currUser.getIdToken(true).then((idToken) => {
                const resetChatPromises: Promise<any>[] = [];
                if (this.$data.genericModeDataForm.linkId) {
                  // Delete old short link.
                  resetChatPromises.push(deleteDoc(doc(currFirestore, 'shortPermalinks', this.$data.genericModeDataForm.linkId)));

                  // Delete chat.
                  resetChatPromises.push(
                    standardApiFetch(endpoints.deleteChat, {
                      idToken,
                      userId: this.$store.state.userId,
                      modeId: this.$data.genericModeDataForm.docId,
                    })
                  );

                  // Create new short link.
                  resetChatPromises.push(
                    standardApiFetch(endpoints.createShortPermalink, {
                      idToken,
                      userId: this.$store.state.userId,
                      modeId: this.$data.genericModeDataForm.docId,
                    })
                  );

                  Promise.all(resetChatPromises)
                    .then((results) => {
                      this.$data.genericModeDataForm.linkId = (results[2] as SitchApiSuccessfulResponse).successfulResponse.linkId;
                      const combinedModeData = getCombinedModeFormData(this) as ChatMode;
                      saveMode({ ...combinedModeData });
                    })
                    .catch((error) => {
                      showError(`Could not reset the link`, error, true);
                    });
                }
              });
            } else {
              showError(`Cannot reset a chat room that does not exist.`);
            }
          })
          .catch(() => {
            showError(`Cannot get the chat room data`);
          });
      });
    },
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      const combinedModeData = getCombinedModeFormData(this) as ChatMode;
      saveMode({ ...combinedModeData }).then(() => {
        const chatRoomDoc = doc(currFirestore, 'chatRooms', this.$data.genericModeDataForm.docId);
        getDoc(chatRoomDoc).then((chatRoomDocSnap) => {
          if (chatRoomDocSnap.exists()) {
            updateDoc(chatRoomDoc, {
              message: combinedModeData.message,
              hasAutoTranslate: combinedModeData.hasAutoTranslate,
              maximumParticipants: combinedModeData.maximumParticipants,
            });
          }
        });
      });
    },
  },
});
