
import Vue from 'vue';
import validationRules from '@/validation-rules';
import { modeFormMixin } from '@/mixins';
import { getEmptyLink } from '@/constants';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { handleImages } from '@/util-functions/image-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { t } from '@/util-functions/language-utils';
import { saveMode } from '@/util-functions/firestore-mode-utils';
import SitchImageUpload from '@/components/custom-ui-components/SitchImageUpload.vue';

export default Vue.extend({
  mixins: [modeFormMixin],
  components: {
    SitchImageUpload,
  },
  data(): {
    imageSizesForAvatar: ImageSizes;
    imageSizesForLinks: ImageSizes;
    rules: typeof validationRules;
    modeForm: Omit<LinkListMode, keyof Mode>;
    isEmbeddableMap: {
      [linkId: string]: boolean;
    };
  } {
    return {
      imageSizesForAvatar: { small: 400 },
      imageSizesForLinks: { small: 200 },
      rules: validationRules,
      modeForm: {} as Omit<LinkListMode, keyof Mode>,
      isEmbeddableMap: {},
    };
  },
  watch: {
    modeForm() {
      this.onLoad();
    },
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.modeForm.links?.forEach((link: Link) => {
        this.isEmbeddableMap[link.id] = this.everyRulePasses(link);
      });
      this.$forceUpdate();
    },
    onUrlChange(link: Link) {
      this.everyRulePasses(link);
      this.$forceUpdate();
    },
    everyRulePasses(link: Link): boolean {
      const everyRulePasses = this.rules.requiredEmbeddableUrlRules.every((ruleFunction) => {
        return ruleFunction(link.url) === true;
      });
      this.isEmbeddableMap[link.id] = everyRulePasses;
      return everyRulePasses;
    },
    onAddLink() {
      const LINK_MAX = 100;
      if (this.modeForm.links.length > LINK_MAX) {
        showError(t?.linkItemMax.supplant([LINK_MAX]));
        return;
      }
      this.modeForm.links.push(getEmptyLink());
    },
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      const mixinMethods = this as any as ModeMixinMethods;
      const postDeletionPreviewArray: Link[] = mixinMethods.postDeletionPreviewArray(this.modeForm.links);

      if (!postDeletionPreviewArray.length) {
        showError(t.mustHaveAtleastOneItem);
        return;
      }

      mixinMethods
        .pruneAndCommitDeletionsJustBeforeCreatingCombinedModeData()
        .then(() => {
          const promiseArray: Promise<any>[] = [];
          const combinedModeData = getCombinedModeFormData(this) as LinkListMode;
          const storagePath = `userFiles/${this.$store.state.userId}/${combinedModeData.docId}`;

          // Handle mode image.
          handleImages(
            combinedModeData,
            [
              {
                key: 'images',
                imageSizes: this.imageSizesForAvatar,
              },
            ],
            promiseArray,
            storagePath
          );

          // Cycle through every link in the form.
          combinedModeData.links.forEach((item) => {
            handleImages(
              item,
              [
                {
                  key: 'images',
                  imageSizes: this.imageSizesForLinks,
                },
              ],
              promiseArray,
              storagePath
            );
          });

          Promise.all(promiseArray).then(() => {
            saveMode({ ...combinedModeData });
          });
        })
        .catch(() => {
          showError(t.somethingWentWrongWhenDeletingAnItem);
        });
    },
  },
});
