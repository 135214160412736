
import Vue from 'vue';
import { modeFormMixin } from '@/mixins';
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { saveMode } from '@/util-functions/firestore-mode-utils';
import { t } from '@/util-functions/language-utils';
import { showError } from '@/util-functions/misc-firestore-utils';

export default Vue.extend({
  mixins: [modeFormMixin],
  components: {
    codemirror,
  },
  data(): {
    modeForm: Omit<HtmlMode, keyof Mode>;
    htmlCmOptions: Record<string, unknown>;
    jsCmOptions: Record<string, unknown>;
    cssCmOptions: Record<string, unknown>;
  } {
    return {
      modeForm: {} as Omit<HtmlMode, keyof Mode>,
      htmlCmOptions: {
        lineNumbers: true,
        mode: 'text/html',
      },
      jsCmOptions: {
        lineNumbers: true,
        mode: 'text/javascript',
      },
      cssCmOptions: {
        lineNumbers: true,
        mode: 'text/css',
      },
    };
  },
  methods: {
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      if (!this.modeForm.html && !this.modeForm.js) {
        showError(t.htmlOrJsMustBePopulated);
        return;
      }
      const combinedModeData = getCombinedModeFormData(this);
      saveMode({ ...combinedModeData });
    },
  }
});
