
import Vue from 'vue';
import validationRules from '@/validation-rules';
import SitchTextEditor from '@/components/custom-ui-components/SitchTextEditor.vue';
import { modeFormMixin } from '@/mixins';
import { getEmptyAccordionSection } from '@/constants';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { saveMode } from '@/util-functions/firestore-mode-utils';
import { t } from '@/util-functions/language-utils';
import { showError } from '@/util-functions/misc-firestore-utils';

export default Vue.extend({
  mixins: [modeFormMixin],
  components: {
    SitchTextEditor,
  },
  data(): {
    rules: typeof validationRules;
    modeForm: Omit<AccordionMode, keyof Mode>;
  } {
    return {
      rules: validationRules,
      modeForm: {} as Omit<AccordionMode, keyof Mode>,
    };
  },
  methods: {
    onAddSection() {
      const SECTION_MAX = 100;
      if (this.modeForm.sections.length > SECTION_MAX) {
        showError(t?.sectionItemMax.supplant([SECTION_MAX]));
        return;
      }
      this.modeForm.sections.push(getEmptyAccordionSection());
    },
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      const mixinMethods = (this as any) as ModeMixinMethods;
      const postDeletionPreviewArray: AccordionSection[] = mixinMethods.postDeletionPreviewArray(this.modeForm.sections);

      if (!postDeletionPreviewArray.length) {
        showError(t.mustHaveAtleastOneItem);
        return;
      }

      mixinMethods
        .pruneAndCommitDeletionsJustBeforeCreatingCombinedModeData()
        .then(() => {
          const promiseArray: Promise<any>[] = [];
          const combinedModeData = getCombinedModeFormData(this) as AccordionMode;

          Promise.all(promiseArray).then(() => {
            saveMode({ ...combinedModeData });
          });
        })
        .catch((error) => {
          showError(t.somethingWentWrongWhenDeletingAnItem, error, true);
        });
    },
  },
});
