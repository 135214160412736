
import Vue from 'vue';
import { modeFormMixin } from '@/mixins';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { saveMode } from '@/util-functions/firestore-mode-utils';
import { eTriviaCategories, eTriviaDifficulty } from '@/enums';
import { eNumToTextValueArray } from '@/util-functions/misc-utils';

export default Vue.extend({
  mixins: [modeFormMixin],
  data(): {
    modeForm: Omit<TriviaMode, keyof Mode>;
    categoryOptions: TextValue[];
    difficultyOptions: TextValue[];
  } {
    return {
      modeForm: {} as Omit<TriviaMode, keyof Mode>,
      categoryOptions: eNumToTextValueArray(eTriviaCategories),
      difficultyOptions: eNumToTextValueArray(eTriviaDifficulty),
    };
  },
  methods: {
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      const combinedModeData = getCombinedModeFormData(this) as TriviaMode;

      saveMode({ ...combinedModeData });
    },
  },
});
