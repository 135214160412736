
import Vue from 'vue';
import stripeCurrencies from '@/stripe-currencies';
import { canadianRates, usRates } from '@/sales-tax-rates';
import { modeFormMixin } from '@/mixins';
import { getEmptyCategory, getEmptyDetailedAddress, getEmptyShopItem } from '@/constants';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { handleImages } from '@/util-functions/image-utils';
import { eDeliveryTypes, eModeType } from '@/enums';
import { collection, doc, getDocs, writeBatch } from 'firebase/firestore';
import { saveMode } from '@/util-functions/firestore-mode-utils';
import { currFirestore } from '@/util-functions/initialization-utils';
import { t } from '@/util-functions/language-utils';
import { showError, generateId } from '@/util-functions/misc-firestore-utils';
import { getLocationAddress } from '@/util-functions/misc-utils';
import { onConnectStripeAccount } from '@/util-functions/stripe-utils';
import { getUserModeGatewayDoc } from '@/util-functions/user-utils';
import SitchImageUpload from '@/components/custom-ui-components/SitchImageUpload.vue';
import SitchModifierGroupListConfig from '@/components/custom-ui-components/SitchModifierGroupListConfig.vue';
import SitchAddress from '@/components/custom-ui-components/SitchAddress.vue';
import countries from '@/countries';

const promoCodesCollectionName = 'promoCodes';

export default Vue.extend({
  mixins: [modeFormMixin],
  components: {
    SitchModifierGroupListConfig,
    SitchAddress,
    SitchImageUpload,
  },
  data(): {
    imageSizesForAvatar: ImageSizes;
    imageSizesForItems: ImageSizes;
    modifierMaxQuantityOptions: number[];
    showModifiersHint: boolean;
    stripeCurrencies: typeof stripeCurrencies;
    eDeliveryTypes: typeof eDeliveryTypes;
    promoCodes: PromoCode[];
    modeForm: Omit<ShopMode, keyof Mode>;
    showSalesTaxLocationsDialog: boolean;
    canadianRates: { code: string; taxRate: number; name: string }[];
    usRates: { code: string; taxRate: number; name: string }[];
    allRates: { [code: string]: { code: string; taxRate: number; name: string } };
    countriesForDelivery: TextValue[];
  } {
    const modifierMaxQuantityOptions = Array.from(Array(11).keys()).slice(1);
    return {
      imageSizesForAvatar: { small: 400 },
      imageSizesForItems: { small: 200, large: 1080 },
      modifierMaxQuantityOptions,
      showModifiersHint: false,
      stripeCurrencies,
      eDeliveryTypes,
      promoCodes: [],
      modeForm: {} as Omit<ShopMode, keyof Mode>,
      showSalesTaxLocationsDialog: false,
      canadianRates: Object.values(canadianRates).sort((a: any, b: any) => (a.name || '').localeCompare(b.name || '')),
      usRates: Object.values(usRates).sort((a: any, b: any) => (a.name || '').localeCompare(b.name || '')),
      allRates: { ...usRates, ...canadianRates },
      countriesForDelivery: countries,
    };
  },
  computed: {
    allowedCountriesIncludeCa(): boolean {
      return this.modeForm.allowedCountriesForDelivery.includes('CA');
    },
    allowedCountriesIncludeUs(): boolean {
      return this.modeForm.allowedCountriesForDelivery.includes('US');
    },
    countriesForFreeShipping() {
      if (this.modeForm.allowedCountriesForDelivery?.length) {
        return countries.filter((c) => {
          return (this as any).modeForm.allowedCountriesForDelivery.includes(c.value);
        });
      }
      return countries;
    },
    stripeAccounts(): StripeAccount[] {
      return this.$store.getters.stripeAccounts;
    },
    customFormModesArray(): { docId: string; name: string }[] {
      const modes = Object.values(this.$store.state.modes) as AnyMode[];
      return [{ docId: '', name: t.none }, ...modes.filter((mode: AnyMode) => mode.type === eModeType.customForm)];
    },
  },
  watch: {
    modeForm() {
      this.onLoad();
    },
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.checkForDeletedCustomForms();
    },
    checkForDeletedCustomForms() {
      const preSubmissionSelectedCustomForm = this.customFormModesArray.find((mode) => mode.docId === this.modeForm.preSubmissionCustomFormModeId);
      const postSubmissionSelectedCustomForm = this.customFormModesArray.find((mode) => mode.docId === this.modeForm.postSubmissionCustomFormModeId);

      // If the custom form we were using is deleted, reset this.
      if (!preSubmissionSelectedCustomForm) {
        this.modeForm.preSubmissionCustomFormModeId = '';
      }
      if (!postSubmissionSelectedCustomForm) {
        this.modeForm.preSubmissionCustomFormModeId = '';
      }
    },
    taxRateLocationsSelected(): number {
      return Object.keys(this.modeForm.locationsToComputeTaxRateFor || {}).length;
    },
    toggleTaxLocation(locationCode: string) {
      if (this.modeForm.locationsToComputeTaxRateFor[locationCode]) {
        Vue.delete(this.modeForm.locationsToComputeTaxRateFor, locationCode);
      } else {
        this.modeForm.locationsToComputeTaxRateFor[locationCode] = this.allRates[locationCode].taxRate;
      }
      this.$forceUpdate();
    },
    changeRateForTaxLocation(val: string, locationCode: string) {
      this.modeForm.locationsToComputeTaxRateFor[locationCode] = Number(val) || 0;
      this.$forceUpdate();
    },
    onGetAddressData(addressData: any, place: any) {
      this.modeForm.locationAddress = getLocationAddress(addressData, place, this.modeForm);
    },
    onAddressInputChange(input: string) {
      if (!input) {
        this.modeForm.locationAddress = getEmptyDetailedAddress();
      }
    },
    shopItemsNotAlreadyInOtherCategories(currCategory: Category): ShopItem[] {
      const allCategorizedShopItemIds: string[] = this.modeForm.shopItemList
        .filter((shopItem) => {
          return this.modeForm.categories
            .flatMap((category) => {
              if (category.id !== currCategory.id) {
                return category.shopItemIds;
              }
              return [];
            })
            .includes(shopItem.id);
        })
        .map((shopItem) => shopItem.id);
      return this.modeForm.shopItemList.filter((shopItem) => {
        return !allCategorizedShopItemIds.includes(shopItem.id);
      });
    },
    onAddCategory() {
      const CATEGORY_MAX = 50;
      if (this.modeForm.shopItemList.length > CATEGORY_MAX) {
        showError(t?.shopItemMax.supplant([CATEGORY_MAX]));
        return;
      }
      this.modeForm.categories.push(getEmptyCategory());
    },
    onShoptItemStockChange(shopItem: ShopItem, newStock: number) {
      shopItem.isSoldOut = newStock <= 0;
    },
    onFormPopulatedForEdit() {
      // Get the mode objects based on ids
      getDocs(collection(currFirestore, getUserModeGatewayDoc().path, 'modes', this.$data.genericModeDataForm.docId, promoCodesCollectionName))
        .then((querySnapshot) => {
          this.promoCodes = [];
          querySnapshot.forEach((promoCodeDocSnap) => {
            this.promoCodes.push(promoCodeDocSnap.data() as PromoCode);
          });
        })
        .catch((error: any) => {
          showError(`Cannot get promo codes.`, error, true);
        });
    },
    onConnectStripeAccount() {
      onConnectStripeAccount();
    },
    getPreviewImage(shopItem: ShopItem) {
      return shopItem?.images?.[0]?.smallImageUrl || shopItem?.images?.[0]?.smallImageBase64Preview;
    },
    forceUpdate() {
      this.$forceUpdate();
    },
    upperCasePromoCodes() {
      this.promoCodes.forEach((promoCode: PromoCode) => {
        promoCode.code = promoCode.code.toUpperCase();
      });
    },
    moveShopItemUp(index: number) {
      const item = this.modeForm.shopItemList.splice(index, 1);
      this.modeForm.shopItemList.splice(index - 1, 0, item[0]);
    },
    moveShopItemDown(index: number) {
      const item = this.modeForm.shopItemList.splice(index, 1);
      this.modeForm.shopItemList.splice(index + 1, 0, item[0]);
    },
    onAddPromoCode() {
      // This limit is both for aesthetics and to appease Stripe's character limit on descriptions.
      const PROMO_CODE_MAX = 50;
      if (this.promoCodes.length > PROMO_CODE_MAX) {
        showError(t?.promoCodeMax.supplant([PROMO_CODE_MAX]));
        return;
      }
      const promoCode: PromoCode = { id: generateId(), code: '', discountPercentage: 0, isHidden: false };
      this.promoCodes.push(promoCode);
    },
    onAddShopItem() {
      // This limit is both for aesthetics and to appease Stripe's character limit on descriptions.
      const SHOP_ITEM_MAX = 100;
      if (this.modeForm.shopItemList.length > SHOP_ITEM_MAX) {
        showError(t?.shopItemMax.supplant([SHOP_ITEM_MAX]));
        return;
      }
      this.modeForm.shopItemList.push(getEmptyShopItem());
    },
    onAddModifierGroup(shopItem: ShopItem) {
      const MODIFIER_MAX = 10;
      shopItem.modifierGroups = shopItem.modifierGroups || [];
      if (shopItem.modifierGroups.length > MODIFIER_MAX) {
        showError(t?.modifierMax.supplant([MODIFIER_MAX]));
        return;
      }
      shopItem.modifierGroups.push({
        id: generateId(),
        name: '',
        description: '',
        isHidden: false,
        isSoldOut: false,
        modifiers: [],
        numberOfSelections: 1,
        mustPickExactAmount: true,
        canSelectMultipleOfSameModifier: false,
      } as ModifierGroup);
      (this.$refs.groupListConfig as any).$forceUpdate();
      this.$forceUpdate();
    },
    onSelectLocationsToComputeSalesTaxFor() {
      this.showSalesTaxLocationsDialog = true;
    },
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      const mixinMethods = this as any as ModeMixinMethods;
      const postDeletionPreviewArray: ShopItem[] = mixinMethods.postDeletionPreviewArray(this.modeForm.shopItemList);

      if (!postDeletionPreviewArray.length) {
        showError(t.mustHaveAtleastOneItem);
        return;
      }

      if (this.modeForm.hasMaxOrderDistance && !this.modeForm.locationAddress.formattedAddress && this.modeForm.deliveryType === eDeliveryTypes.local) {
        showError(t.addressRequired);
        return;
      }

      if (this.modeForm.computeTaxRateBasedOnLocation && !Object.values(this.modeForm.locationsToComputeTaxRateFor).length && this.modeForm.deliveryType === eDeliveryTypes.shipping) {
        showError(t.mustSpecifyTaxRateForAtLeastOneLocation);
        return;
      }

      let emptyModifierGroup = '';
      let quantityMismatch = false;
      let offendingShopItem = '';

      postDeletionPreviewArray.forEach((shopItem) => {
        if (shopItem.modifierGroups?.length) {
          // If this shop item has modifier groups.
          shopItem.modifierGroups.some((modifierGroup) => {
            // Check for modifier group that has an empty modifiers array.
            const isEmptyModifierGroup = !modifierGroup.modifiers.length;
            if (isEmptyModifierGroup) {
              offendingShopItem = shopItem.name;
              emptyModifierGroup = modifierGroup.name;
            }
            return isEmptyModifierGroup;
          });
        }

        if (shopItem.minimumQuantity && shopItem.maximumQuantity && shopItem.minimumQuantity > shopItem.maximumQuantity) {
          offendingShopItem = shopItem.name;
          quantityMismatch = true;
        }
      });

      if (quantityMismatch) {
        showError(t.quantityMismatch.supplant([offendingShopItem]));
        return;
      }

      if (emptyModifierGroup) {
        showError(t.cannotHaveAnEmptyModifierGroup.supplant([emptyModifierGroup, offendingShopItem]));
        return;
      }

      mixinMethods
        .pruneAndCommitDeletionsJustBeforeCreatingCombinedModeData()
        .then(() => {
          const promiseArray: Promise<any>[] = [];
          let combinedModeData = getCombinedModeFormData(this) as ShopMode;
          const promoCodesInUse = this.promoCodes.map((promoCode) => promoCode.code);
          combinedModeData.hasPromoCodes = Boolean(promoCodesInUse.length);
          const storagePath = `userFiles/${this.$store.state.userId}/${combinedModeData.docId}`;
          this.upperCasePromoCodes();

          // Handle mode image.
          handleImages(
            combinedModeData,
            [
              {
                key: 'images',
                imageSizes: this.imageSizesForAvatar,
              },
            ],
            promiseArray,
            storagePath
          );

          // Cycle through every shop item in the form.
          combinedModeData.shopItemList.forEach((item) => {
            handleImages(
              item,
              [
                {
                  key: 'images',
                  imageSizes: this.imageSizesForItems,
                },
              ],
              promiseArray,
              storagePath
            );
          });

          Promise.all(promiseArray).then(() => {
            saveMode({ ...combinedModeData }).then((newlyCreatedOrUpdatedModeDoc) => {
              const promoCodeCollectionRef = collection(currFirestore, newlyCreatedOrUpdatedModeDoc.path, promoCodesCollectionName);
              getDocs(promoCodeCollectionRef)
                .then((querySnapshot) => {
                  const promoCodesUpdateBatch = writeBatch(currFirestore);

                  // Delete promo codes not in use. The id  of each document is the the promo code itself.
                  querySnapshot.forEach((promoCodeDocSnap) => {
                    if (!promoCodesInUse.includes(promoCodeDocSnap.id)) {
                      promoCodesUpdateBatch.delete(promoCodeDocSnap.ref);
                    }
                  });

                  // Write the promo codes that are in use.
                  this.promoCodes.forEach((promoCode) => {
                    promoCodesUpdateBatch.set(doc(currFirestore, promoCodeCollectionRef.path, promoCode.code), {
                      ...promoCode,
                    });
                  });

                  promoCodesUpdateBatch.commit().catch((error: any) => {
                    showError(`Could not update promo codes.`, error, true);
                  });
                })
                .catch((error: any) => {
                  showError(`Could not get promo codes.`, error, true);
                });
            });
          });
        })
        .catch(() => {
          showError(t.somethingWentWrongWhenDeletingAnItem);
        });
    },
  },
});
