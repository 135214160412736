
import Vue, { PropType } from 'vue';
import SitchModifierGroupConfig from '@/components/custom-ui-components/SitchModifierGroupConfig.vue';

export default Vue.extend({
  components: {
    SitchModifierGroupConfig,
  },
  props: {
    value: {
      type: Array as PropType<ModifierGroup[]>,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data(): {
    modifierGroupList: ModifierGroup[];
  } {
    return {
      modifierGroupList: this.value,
    };
  },
  methods: {
    removeModifierGroupFunc(index: number) {
      this.modifierGroupList.splice(index, 1);
      this.$emit('input', this.modifierGroupList);
    },
    moveUp(itemId: string) {
      const indexForitemToMove = this.modifierGroupList.findIndex((item) => {
        return itemId === item?.id;
      });
      const itemToMove = this.modifierGroupList[indexForitemToMove];
      this.modifierGroupList[indexForitemToMove] = this.modifierGroupList[indexForitemToMove - 1];
      this.modifierGroupList[indexForitemToMove - 1] = itemToMove;
      this.onOrderChange();
    },
    moveDown(itemId: string) {
      const indexForitemToMove = this.modifierGroupList.findIndex((item) => {
        return itemId === item?.id;
      });
      const itemToMove = this.modifierGroupList[indexForitemToMove];
      this.modifierGroupList[indexForitemToMove] = this.modifierGroupList[indexForitemToMove + 1];
      this.modifierGroupList[indexForitemToMove + 1] = itemToMove;
      this.onOrderChange();
    },
    onOrderChange() {
      this.$emit('input', this.modifierGroupList);
      this.$forceUpdate();
    },
  },
});
