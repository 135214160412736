
import Vue from 'vue';
import { modeFormMixin } from '@/mixins';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { t } from '@/util-functions/language-utils';
import { saveMode } from '@/util-functions/firestore-mode-utils';
// Wifi Types: ['WPA', 'WPA2', 'WEP', 'WPA-PSK', 'WPA2-PSK', 'nopass']

export default Vue.extend({
  mixins: [modeFormMixin],
  data(): {
    modeForm: Omit<WifiMode, keyof Mode>;
  } {
    return {
      modeForm: {} as Omit<WifiMode, keyof Mode>,
    };
  },
  methods: {
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      const combinedModeData = getCombinedModeFormData(this) as WifiMode;

      if (!combinedModeData.wifiEncryption) {
        showError(t?.mustSelectAnEncryptionType);
        return;
      }
      saveMode({ ...combinedModeData });
    },
  },
});
