
import Vue from 'vue';
import { modeFormMixin } from '@/mixins';
import { eModeType } from '@/enums';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { draggableOptions } from '@/constants';
import { saveMode } from '@/util-functions/firestore-mode-utils';

export default Vue.extend({
  mixins: [modeFormMixin],
  data(): {
    draggableOptions: Record<string, unknown>;
    selectedModesToGroup: AnyMode[];
    modeForm: Omit<GroupMode, keyof Mode>;
  } {
    return {
      draggableOptions: draggableOptions,
      selectedModesToGroup: [],
      modeForm: {} as Omit<GroupMode, keyof Mode>,
    };
  },
  computed: {
    currUserModeGateway(): PublicUserModeGateway {
      return this.$store.state.currUserModeGateway;
    },
    modes(): Record<string, AnyMode> {
      return this.$store.state.modes;
    },
    modesForGroups(): Mode[] {
      const usedNameCount: { [name: string]: number } = {};      
      return (Object.values(this.modes) as Mode[])
        .filter((mode) => {
          const isNotNonViewableMode = ![eModeType.site, eModeType.group].includes(mode.type as eModeType);
          const notSelf = mode.docId !== this.$data.genericModeDataForm.docId;
          return isNotNonViewableMode && notSelf;
        })
        .map((mode: Mode) => {
          usedNameCount[mode.name] = usedNameCount[mode.name] ? usedNameCount[mode.name] + 1 : 1;
          if (usedNameCount[mode.name] > 1) {
            return { ...mode, name: `${mode.name} (${mode.displayName || usedNameCount[mode.name]})` };
          }
          return mode;
        })
        .sort((a: Mode, b: Mode) => {
          return a.index - b.index;
        });
    },
  },
  methods: {
    onRemoveMode(modeDocId: string) {
      this.selectedModesToGroup = this.selectedModesToGroup.filter((mode) => mode.docId !== modeDocId);
    },
    onFormPopulatedForEdit() {
      // Get the mode objects based on ids.
      if (this.$parent?.$data.currModeForEdit.groupModeIds) {
        this.selectedModesToGroup = this.$parent?.$data.currModeForEdit.groupModeIds
          .map((modeId: string) => {
            return this.modes[modeId];
          })
          .filter((mode: AnyMode) => Boolean(mode));
      }
    },
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      this.modeForm.groupModeIds = this.selectedModesToGroup.map((mode: Mode) => {
        return mode.docId;
      });

      const combinedModeData = getCombinedModeFormData(this) as GroupMode;

      saveMode({ ...combinedModeData });
    },
  },
});
