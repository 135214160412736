
import Vue from 'vue';
import validationRules from '@/validation-rules';
import { format } from 'date-fns';
import ArticleModeForm from '@/components/ArticleModeForm.vue';
import ProfileModeForm from '@/components/ProfileModeForm.vue';
import UrlRedirectModeForm from '@/components/UrlRedirectModeForm.vue';
import FilesModeForm from '@/components/FilesModeForm.vue';
import LinkListModeForm from '@/components/LinkListModeForm.vue';
import GalleryModeForm from '@/components/GalleryModeForm.vue';
import ShopModeForm from '@/components/ShopModeForm.vue';
import WifiModeForm from '@/components/WifiModeForm.vue';
import PersonalPaymentModeForm from '@/components/PersonalPaymentModeForm.vue';
import BusinessPaymentModeForm from '@/components/BusinessPaymentModeForm.vue';
import GroupModeForm from '@/components/GroupModeForm.vue';
import SiteModeForm from '@/components/SiteModeForm.vue';
import HtmlModeForm from '@/components/HtmlModeForm.vue';
import EventsModeForm from '@/components/EventsModeForm.vue';
import BookingModeForm from '@/components/BookingModeForm.vue';
import CustomFormModeForm from '@/components/CustomFormModeForm.vue';
import BlogModeForm from '@/components/BlogModeForm.vue';
import AccordionModeForm from '@/components/AccordionModeForm.vue';
import ReturnToModeForm from '@/components/ReturnToModeForm.vue';
import ChatModeForm from '@/components/ChatModeForm.vue';
import ChessModeForm from '@/components/ChessModeForm.vue';
import WordleModeForm from '@/components/WordleModeForm.vue';
import TriviaModeForm from '@/components/TriviaModeForm.vue';
import CardModeForm from '@/components/CardModeForm.vue';
import SitchThemeSelector from '@/components/custom-ui-components/SitchThemeSelector.vue';
import { eModeType } from '@/enums';
import {
  PREMIUM_MAX_SHORT_LINKS,
  STANDARD_MAX_SHORT_LINKS,
  getEmptyBasicMode,
  getEmptyBookingMode,
  getEmptyBusinessPaymentsMode,
  getEmptyEventsMode,
  getEmptyFilesMode,
  getEmptyGalleryMode,
  getEmptyGroupMode,
  getEmptyHtmlMode,
  getEmptyLinkListMode,
  getEmptyPersonalPaymentsMode,
  getEmptyProfileMode,
  getEmptyShopMode,
  getEmptySiteMode,
  getEmptyArticleMode,
  getEmptyUrlRedirectMode,
  getEmptyWifiMode,
  getEmptyBlogMode,
  getEmptyAccordionMode,
  getEmptyCustomFormMode,
  endpoints,
  getEmptyLink,
  getEmptyGalleryItem,
  getEmptyBlogPostReference,
  getEmptyAccordionSection,
  getEmptySitchEvent,
  getEmptyChatMode,
  getEmptyReturnToMode,
  getEmptyChessMode,
  getEmptyShopItem,
  getEmptyWordleMode,
  getEmptyTriviaMode,
  getEmptyCardMode,
} from '@/constants';
import { deleteField, doc, writeBatch } from 'firebase/firestore';
import { isProductionEnv, fbAuth, standardApiFetch, currFirestore } from '@/util-functions/initialization-utils';
import { t } from '@/util-functions/language-utils';
import { generateId, showError } from '@/util-functions/misc-firestore-utils';
import { isFeatureEnabled, formatCurrency, getParameterByName, getModeIcon } from '@/util-functions/misc-utils';
import { showConfirmation, showSuccess } from '@/util-functions/notice-utils';
import { copyText, deleteCustomPermalink, isPremiumActivated, updateUserLocal, batchUpdateUserDoc } from '@/util-functions/user-utils';
import { hideLoading } from '@/util-functions/loading-utils';

enum eModeCategories {
  page,
  payment,
  time,
  combining,
  qr,
  custom,
  forms,
  communication,
  games,
}

function genericModeDataReset() {
  return {
    ...getEmptyBasicMode(),
    docId: generateId(), // Don't remove this, we need a docId generated before we upload to the database for file uploads.
    isForUpdate: false,
  };
}

interface TypeOption {
  name: string;
  value: eModeType;
  description: string;
  category: eModeCategories;
  requiresStripeAccount?: boolean;
  additionalQueryPath?: string;
}

export default Vue.extend({
  props: {
    isForSitchCam: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ArticleModeForm,
    ProfileModeForm,
    UrlRedirectModeForm,
    FilesModeForm,
    LinkListModeForm,
    GalleryModeForm,
    ShopModeForm,
    WifiModeForm,
    PersonalPaymentModeForm,
    BusinessPaymentModeForm,
    GroupModeForm,
    SiteModeForm,
    HtmlModeForm,
    EventsModeForm,
    BookingModeForm,
    CustomFormModeForm,
    BlogModeForm,
    AccordionModeForm,
    ReturnToModeForm,
    ChatModeForm,
    ChessModeForm,
    WordleModeForm,
    TriviaModeForm,
    CardModeForm,
    SitchThemeSelector,
  },
  data(): {
    rules: typeof validationRules;
    eModeType: typeof eModeType;
    typeOptions: TypeOption[];
    alreadyAddedSiteOption: boolean;
    showPermalinkSection: boolean;
    includeFixedAmount: boolean;
    currModeForEdit: Mode | null;
    newCustomPermalinkId: string;
    genericModeDataForm: Mode;
    showTransferPermalinkDialog: boolean;
    customLinkTransferModeId: string;
    formIsDirty: boolean;
    saveIsHappening: boolean;
  } {
    const typeOptions: TypeOption[] = [];
    if (isFeatureEnabled(this.$store.state.featureFlags.profileModes)) {
      typeOptions.push({
        name: t?.profile,
        value: eModeType.profile,
        description: t?.profileDescription,
        category: eModeCategories.page,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.urlRedirectModes)) {
      typeOptions.push({
        name: t?.urlRedirect,
        value: eModeType.urlRedirect,
        description: t?.urlRedirectDescription,
        category: eModeCategories.page,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.personalPaymentModes)) {
      typeOptions.push({
        name: t?.personalPaymentForMenu,
        value: eModeType.personalPayment,
        description: t?.personalPaymentDescription,
        category: eModeCategories.payment,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.businessPaymentModes)) {
      typeOptions.push({
        name: t?.businessPaymentForMenu,
        value: eModeType.businessPayment,
        description: t?.businessPaymentDescription,
        category: eModeCategories.payment,
        requiresStripeAccount: true,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.fileModes)) {
      typeOptions.push({
        name: t?.files,
        value: eModeType.files,
        description: t?.filesDescription,
        category: eModeCategories.page,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.linkListModes)) {
      typeOptions.push({
        name: t?.linkList,
        value: eModeType.linkList,
        description: t?.linkListDescription,
        category: eModeCategories.page,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.galleryModes)) {
      typeOptions.push({
        name: t?.gallery,
        value: eModeType.gallery,
        description: t?.galleryDescription,
        category: eModeCategories.page,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.blogModes)) {
      typeOptions.push({
        name: t?.blog,
        value: eModeType.blog,
        description: t?.blogDescription,
        category: eModeCategories.page,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.shopModes)) {
      typeOptions.push({
        name: t?.shop,
        value: eModeType.shop,
        description: t?.shopDescription,
        category: eModeCategories.payment,
        requiresStripeAccount: true,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.groupModes)) {
      typeOptions.push({
        name: t?.group,
        value: eModeType.group,
        description: t?.groupDescription,
        category: eModeCategories.combining,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.wifiModes)) {
      typeOptions.push({
        name: t?.wifi,
        value: eModeType.wifi,
        description: t?.wifiDescription,
        category: eModeCategories.qr,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.articleModes)) {
      typeOptions.push({
        name: t?.article,
        value: eModeType.article,
        description: t?.articleDescription,
        category: eModeCategories.page,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.accordionModes)) {
      typeOptions.push({
        name: t?.accordion,
        value: eModeType.accordion,
        description: t?.accordionDescription,
        category: eModeCategories.page,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.htmlModes)) {
      typeOptions.push({
        name: t?.html,
        value: eModeType.html,
        description: t?.htmlDescription,
        category: eModeCategories.custom,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.eventsModes)) {
      typeOptions.push({
        name: t?.events,
        value: eModeType.events,
        description: t?.eventsDescription,
        category: eModeCategories.time,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.bookingModes)) {
      typeOptions.push({
        name: t?.booking,
        value: eModeType.booking,
        description: t?.bookingDescription,
        category: eModeCategories.time,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.customFormModes)) {
      typeOptions.push({
        name: t?.customForm,
        value: eModeType.customForm,
        description: t?.customFormDescription,
        category: eModeCategories.forms,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.customFormModes)) {
      typeOptions.push({
        name: t?.contactForm,
        value: eModeType.customForm,
        description: t?.contactFormDescription,
        category: eModeCategories.forms,
        additionalQueryPath: '&cft=contact',
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.customFormModes)) {
      typeOptions.push({
        name: t?.reviewForm,
        value: eModeType.customForm,
        description: t?.reviewFormDescription,
        category: eModeCategories.forms,
        additionalQueryPath: '&cft=review',
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.customFormModes)) {
      typeOptions.push({
        name: t?.guestForm,
        value: eModeType.customForm,
        description: t?.guestbookFormDescription,
        category: eModeCategories.forms,
        additionalQueryPath: '&cft=guestbook',
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.chatModes)) {
      typeOptions.push({
        name: t?.chat,
        value: eModeType.chat,
        description: t?.chatDescription,
        category: eModeCategories.communication,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.returnToModes)) {
      typeOptions.push({
        name: t?.returnTo,
        value: eModeType.returnTo,
        description: t?.returnToDescription,
        category: eModeCategories.page,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.chessModes)) {
      typeOptions.push({
        name: t?.chess,
        value: eModeType.chess,
        description: t?.chessDescription,
        category: eModeCategories.games,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.wordleModes)) {
      typeOptions.push({
        name: 'Wordle',
        value: eModeType.wordle,
        description: t?.wordleDescription,
        category: eModeCategories.games,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.triviaModes)) {
      typeOptions.push({
        name: 'Trivia',
        value: eModeType.trivia,
        description: t?.triviaDescription,
        category: eModeCategories.games,
      });
    }
    if (isFeatureEnabled(this.$store.state.featureFlags.cardModes)) {
      typeOptions.push({
        name: 'Card',
        value: eModeType.card,
        description: t?.cardDescription,
        category: eModeCategories.page,
      });
    }

    const genericModeData: Mode = genericModeDataReset();

    return {
      rules: validationRules,
      eModeType,
      typeOptions,
      alreadyAddedSiteOption: false,
      showPermalinkSection: false,
      includeFixedAmount: false,
      currModeForEdit: null,
      newCustomPermalinkId: '',
      genericModeDataForm: genericModeData,
      showTransferPermalinkDialog: false,
      customLinkTransferModeId: '',
      formIsDirty: false,
      saveIsHappening: false,
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.onRouteChange(next);
  },
  beforeRouteLeave(to, from, next) {
    this.onRouteChange(next);
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
  mounted() {
    const numberOfModes = Object.keys(this.modes).length;

    // You should only be able to make a site if you have two or more modes. Only append this to the options if it was not already appeneded.
    if (!this.alreadyAddedSiteOption && isFeatureEnabled(this.$store.state.featureFlags.siteModes) && numberOfModes > 1) {
      this.typeOptions.push({
        name: t?.site,
        value: eModeType.site,
        description: t?.siteDescription,
        category: eModeCategories.combining,
      });
      this.alreadyAddedSiteOption = true;
    }
    this.populateModeForm();
  },
  watch: {
    $route() {
      //onOptionSelect
      this.populateModeForm();
    },
  },
  computed: {
    modes(): ModesMap {
      return this.$store.state.modes;
    },
    modesWithoutCustomPermalinksArray(): AnyMode[] {
      const modes = Object.values(this.$store.state.modes) as AnyMode[];
      return modes.filter((mode: AnyMode) => mode.docId !== this.currModeForEdit?.docId && !this.$store.state.currUser.permalinks[mode.docId]);
    },
    breadcrumbItems(): {
      text: string;
      disabled?: boolean;
      to?: string;
      exact?: boolean;
    }[] {
      if (!this.genericModeDataForm.type) {
        return [
          {
            text: 'Sitches',
            exact: true,
            to: '/',
          },
          {
            text: t.selectModeType,
            exact: true,
            to: '/SitchForm',
          },
        ];
      }
      return [
        {
          text: 'Sitches',
          exact: true,
          to: '/',
        },
        {
          text: t.selectModeType,
          exact: true,
          to: '/SitchForm',
        },
        {
          text: t[this.genericModeDataForm.type],
          disabled: true,
        },
      ];
    },
    formattedAmount() {
      const mode: any = this.currModeForEdit;
      const currency = mode?.currency || '';
      return mode.amount ? formatCurrency(mode.amount, currency) : '0';
    },
    lowModeCount(): boolean {
      return Object.keys(this.modes).length < 2 && this.$store.getters.sortedFolders.length <= 0;
    },
    permalink(): string | null {
      if (!this.$store.state.currUser.permalinks) {
        return null;
      }
      if (!this.currModeForEdit) {
        return null;
      }
      return this.$store.state.currUser.permalinks[this.currModeForEdit.docId] || null;
    },
    noStripeAccounts(): boolean {
      return !this.$store.getters.stripeAccounts.length;
    },
    fullCustomLink(): string {
      const baseUrl = isProductionEnv ? 'sitch.app' : 'sitch-client-test.web.app';
      return `${baseUrl}/${this.permalink}`;
    },
    modeLink(): string {
      const baseUrl = isProductionEnv ? 'sitch.app' : 'sitch-client-test.web.app';
      const ret = this.currModeForEdit?.linkId ? `${baseUrl}/s/${this.currModeForEdit?.linkId}` : `${baseUrl}?u=${this.$store.state.userId}&am=${this.currModeForEdit?.docId}`;
      if (this.includeFixedAmount) {
        const amount = (this.currModeForEdit as any)?.amount || 0;
        return amount ? `${ret}&amount=${amount}` : ret;
      }
      return ret;
    },
    typeOptionsForPageModes(): TypeOption[] {
      return this.typeOptions.filter((option) => {
        return option.category === eModeCategories.page;
      });
    },
    typeOptionsForCombiningModes(): TypeOption[] {
      return this.typeOptions.filter((option) => {
        return option.category === eModeCategories.combining;
      });
    },
    typeOptionsForQrModes(): TypeOption[] {
      return this.typeOptions.filter((option) => {
        return option.category === eModeCategories.qr;
      });
    },
    typeOptionsForCustomModes(): TypeOption[] {
      return this.typeOptions.filter((option) => {
        return option.category === eModeCategories.custom;
      });
    },
    typeOptionsForPaymentModes(): TypeOption[] {
      return this.typeOptions.filter((option) => {
        return option.category === eModeCategories.payment;
      });
    },
    typeOptionsForScheduleModes(): TypeOption[] {
      return this.typeOptions.filter((option) => {
        return option.category === eModeCategories.time;
      });
    },
    typeOptionsForFormModes(): TypeOption[] {
      return this.typeOptions.filter((option) => {
        return option.category === eModeCategories.forms;
      });
    },
    typeOptionsForCommunicationModes(): TypeOption[] {
      return this.typeOptions.filter((option) => {
        return option.category === eModeCategories.communication;
      });
    },
    typeOptionsForGameModes(): TypeOption[] {
      return this.typeOptions.filter((option) => {
        return option.category === eModeCategories.games;
      });
    },
  },
  methods: {
    copyText(str: string) {
      return copyText(str);
    },
    confirmLeave() {
      return window.confirm(t.unsavedChanges);
    },
    confirmStayInDirtyForm() {
      return this.formIsDirty && !this.confirmLeave();
    },
    beforeWindowUnload(e: BeforeUnloadEvent) {
      if (this.genericModeDataForm.type && this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    },
    populateModeForm() {
      const modes = this.$store.state.modes;
      this.formIsDirty = false;

      // If for Sitch Cam then this setup was already done.
      const currModeForEdit: Mode = modes[getParameterByName('id')];
      const givenType = getParameterByName('type') as eModeType;
      this.genericModeDataForm = genericModeDataReset();

      if (currModeForEdit) {
        this.genericModeDataForm.type = currModeForEdit?.type;
      } else if (givenType) {
        this.genericModeDataForm.type = givenType;
      } else if (this.isForSitchCam) {
        this.currModeForEdit = null;
        this.genericModeDataForm.type = eModeType.files;
        this.genericModeDataForm.name = `${t?.sitchCamDefaultSitchName} ${format(new Date(), 'MMM dd yyyy h:mma')}`;
      }

      // Without knowing the type of mode we cannot even populate an empty form.
      if (!this.genericModeDataForm.type) {
        return;
      }

      // Code for currentModeFormVueComponent has to be on next tick since updating type on genericModeDataForm will only then cause currentModeFormVueComponent to render on next tick.
      Vue.nextTick(() => {
        const currentModeFormVueComponent: any = this.$refs.currentModeFormVueComponent;
        currentModeFormVueComponent.modeForm = {};

        if (!currentModeFormVueComponent) {
          showError(`Despite having a type picked. Somehow the form component for that type has not mounted.`, null, true);
          return;
        }

        this.$store.commit('idsGeneratedSinceLastModeFormPopulation', []);

        // Run this when creating or updating sitches to
        // If updating, it fills in blanks.
        // When creating, we obviously need to start from somewhere and need a blank form.
        switch (this.genericModeDataForm.type) {
          case eModeType.profile:
            currentModeFormVueComponent.modeForm = getEmptyProfileMode();
            break;
          case eModeType.linkList:
            currentModeFormVueComponent.modeForm = getEmptyLinkListMode();
            break;
          case eModeType.businessPayment:
            currentModeFormVueComponent.modeForm = getEmptyBusinessPaymentsMode();
            break;
          case eModeType.files:
            currentModeFormVueComponent.modeForm = getEmptyFilesMode();
            break;
          case eModeType.gallery:
            currentModeFormVueComponent.modeForm = getEmptyGalleryMode();
            break;
          case eModeType.group:
            currentModeFormVueComponent.modeForm = getEmptyGroupMode();
            break;
          case eModeType.site:
            currentModeFormVueComponent.modeForm = getEmptySiteMode();
            break;
          case eModeType.html:
            currentModeFormVueComponent.modeForm = getEmptyHtmlMode();
            break;
          case eModeType.shop:
            currentModeFormVueComponent.modeForm = getEmptyShopMode();
            break;
          case eModeType.personalPayment:
            currentModeFormVueComponent.modeForm = getEmptyPersonalPaymentsMode();
            break;
          case eModeType.wifi:
            currentModeFormVueComponent.modeForm = getEmptyWifiMode();
            break;
          case eModeType.urlRedirect:
            currentModeFormVueComponent.modeForm = getEmptyUrlRedirectMode();
            break;
          case eModeType.article:
            currentModeFormVueComponent.modeForm = getEmptyArticleMode();
            break;
          case eModeType.booking:
            currentModeFormVueComponent.modeForm = getEmptyBookingMode();
            break;
          case eModeType.events:
            currentModeFormVueComponent.modeForm = getEmptyEventsMode();
            break;
          case eModeType.blog:
            currentModeFormVueComponent.modeForm = getEmptyBlogMode();
            break;
          case eModeType.accordion:
            currentModeFormVueComponent.modeForm = getEmptyAccordionMode();
            break;
          case eModeType.customForm:
            currentModeFormVueComponent.modeForm = getEmptyCustomFormMode();
            break;
          case eModeType.chat:
            currentModeFormVueComponent.modeForm = getEmptyChatMode();
            break;
          case eModeType.returnTo:
            currentModeFormVueComponent.modeForm = getEmptyReturnToMode();
            break;
          case eModeType.chess:
            currentModeFormVueComponent.modeForm = getEmptyChessMode();
            break;
          case eModeType.wordle:
            currentModeFormVueComponent.modeForm = getEmptyWordleMode();
            break;
          case eModeType.trivia:
            currentModeFormVueComponent.modeForm = getEmptyTriviaMode();
            break;
          case eModeType.card:
            currentModeFormVueComponent.modeForm = getEmptyCardMode();
            break;
        }

        if (currModeForEdit) {
          this.currModeForEdit = currModeForEdit;
          this.genericModeDataForm.isForUpdate = true;

          const currModeForEditCopy: any = { ...currModeForEdit };

          // Populate common mode properties into genericModeDataForm.
          for (const prop of Object.keys(getEmptyBasicMode())) {
            (this.genericModeDataForm as any)[prop] = currModeForEditCopy[prop];
          }

          // Populate the rest into the type specific child component.
          for (const prop of Object.keys(currModeForEditCopy)) {
            if (currentModeFormVueComponent.$data[prop] !== undefined) {
              showError(`WARNING: No keys in modeForm should exist in $data for the currentModeFormVueComponent. This can cause problems for onMarkForDeletion`, null, true);
            }
            if (!Object.keys(getEmptyBasicMode()).includes(prop)) {
              // To make sure we don't override the parent forms generic mode fields.
              if (Array.isArray(currModeForEditCopy[prop])) {
                currentModeFormVueComponent.modeForm[prop] = [...currModeForEditCopy[prop]];

                // Fill in any missing or newly added fields on the item arrays for the mode being loaded in.
                let itemTemplate: any = null;
                switch (`${currModeForEditCopy.type}.${prop}`) {
                  case `${eModeType.linkList}.links`:
                    itemTemplate = getEmptyLink();
                    break;
                  case `${eModeType.gallery}.galleryItems`:
                    itemTemplate = getEmptyGalleryItem();
                    break;
                  case `${eModeType.linkList}.postReferences`:
                    itemTemplate = getEmptyBlogPostReference();
                    break;
                  case `${eModeType.accordion}.sections`:
                    itemTemplate = getEmptyAccordionSection();
                    break;
                  case `${eModeType.shop}.shopItemList`:
                    itemTemplate = getEmptyShopItem();
                    break;
                  case `${eModeType.events}.events`:
                    itemTemplate = getEmptySitchEvent();
                    break;
                }

                if (itemTemplate) {
                  currentModeFormVueComponent.modeForm[prop] = currentModeFormVueComponent.modeForm[prop].map((el: any) => {
                    return {
                      ...itemTemplate,
                      ...el,
                    };
                  });
                }

                /*
              Shallow copying messes up firebase timestamp objects so we skip those. Firebase timestamps are objects that contains the keys
              "seconds" and "nanoseconds". So we do some duck typing to find the timestamp obejcts to skip. It's probably safe to check for
              just the "nanoseconds" key because how many other objects in this project will have a key called nanoseconds? I can't even
              resolve a Date object to nanoseconds in pure JavaScript.

              We also never manipulate timestamps when editing a mode so we don't have to worry about corrupting the mode map in global state.
              */
              } else if (
                currModeForEditCopy[prop] !== null &&
                currModeForEditCopy[prop] !== undefined &&
                typeof currModeForEditCopy[prop] === 'object' &&
                !Object.keys(currModeForEditCopy[prop]).includes('nanoseconds')
              ) {
                currentModeFormVueComponent.modeForm[prop] = { ...currModeForEditCopy[prop] };
              } else {
                if (!(typeof currentModeFormVueComponent.modeForm[prop] === 'object' && !currModeForEditCopy[prop])) {
                  currentModeFormVueComponent.modeForm[prop] = currModeForEditCopy[prop];
                }
              }
            }
          }
          if (currentModeFormVueComponent.onFormPopulatedForEdit) {
            currentModeFormVueComponent.onFormPopulatedForEdit();
          }
        } else {
          this.currModeForEdit = null;
          if (currentModeFormVueComponent.$refs.modeForm) {
            currentModeFormVueComponent.$refs.modeForm.resetValidation();
          }
          if (currentModeFormVueComponent.onNewModeFormPopulationDone) {
            currentModeFormVueComponent.onNewModeFormPopulationDone();
          }
        }
        this.$forceUpdate();
      });
    },
    onOptionSelect(option: TypeOption) {
      const type = option.value;
      const additionalQueryPath = option.additionalQueryPath || '';
      const folderId = getParameterByName('folderId');
      if (folderId) {
        this.$router.push({ path: `/SitchForm?type=${type}&folderId=${folderId}${additionalQueryPath}` });
      } else {
        this.$router.push({ path: `/SitchForm?type=${type}${additionalQueryPath}` });
      }
    },
    getModeIcon(type: eModeType) {
      return getModeIcon(type);
    },
    onDeletePermaLink() {
      showConfirmation(t?.permalinkDeleteConfirmation.supplant([this.fullCustomLink]), () => {
        if (!this.currModeForEdit?.docId || !this.permalink) {
          return;
        }
        deleteCustomPermalink(this.currModeForEdit?.docId, this.permalink).then(() => {
          this.showPermalinkSection = true;
          showSuccess(t?.permalinkDeleted);
        });
      });
    },
    onCreatePermalink() {
      if (!(this.$refs.permalinkForm as any).validate()) {
        showError(t?.formErrors);
        return;
      }

      const currUser = fbAuth.currentUser;
      // const currUser = firebase

      if (!currUser) {
        showError(`No logged in user`, null, true);
        return;
      }

      const numberOfCustomLinks = Object.keys(this.$store.state.currUser.permalinks).length;
      if (isPremiumActivated()) {
        if (numberOfCustomLinks >= PREMIUM_MAX_SHORT_LINKS) {
          showError(t?.customLinkLimitPremium);
          return;
        }
      } else {
        if (numberOfCustomLinks >= STANDARD_MAX_SHORT_LINKS) {
          showError(t?.customLinkLimitStandard);
          return;
        }
      }

      const restrictedPermalinks = [
        'profile',
        'personalpayment',
        'businesspayment',
        'text',
        'files',
        'linklist',
        'gallery',
        'group',
        'shop',
        'wifi',
        'html',
        'redirect',
        'booking',
        'events',
        'linkactions',
        'accordion',
        's',
        'links',
      ];

      if (restrictedPermalinks.includes(this.newCustomPermalinkId.toLowerCase())) {
        showError(t.customLinkUnavailable);
        return;
      }

      const currModeForEdit: Mode = this.currModeForEdit as Mode;

      currUser
        .getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          standardApiFetch(endpoints.createCustomPermalink, {
            idToken,
            userId: this.$store.state.userId,
            modeId: currModeForEdit.docId,
            linkId: this.newCustomPermalinkId.toLowerCase(),
            premiumSubscriptionId: this.$store.state.currUserModeGateway?.premiumSubscriptionId || '',
          }).then(() => {
            const newPermalinks = {
              ...this.$store.state.currUser.permalinks,
              [currModeForEdit.docId]: this.newCustomPermalinkId,
            };
            updateUserLocal({
              permalinks: newPermalinks,
            });
            showSuccess(t?.permalinkCreated);
          });
        })
        .catch((error) => {
          showError(`Could not get user token to make request`, error, true);
        });
    },
    clearFormValidations() {
      Vue.nextTick(() => {
        const currentModeFormVueComponent: any = this.$refs.currentModeFormVueComponent;
        if (currentModeFormVueComponent.$refs.modeForm) {
          (currentModeFormVueComponent as any).$refs.modeForm.resetValidation();
        }
      });
    },
    closeForm() {
      this.genericModeDataForm.type = null;
      this.$router.replace({ path: '/SitchForm' });
    },
    onTransferPermalinkDialog() {
      if (!this.permalink || !this.customLinkTransferModeId) {
        return;
      }
      const batch = writeBatch(currFirestore);
      const onBatchFailArray: { (): void }[] = [];
      const customPermalinkDoc = doc(currFirestore, 'customPermalinks', this.permalink);

      batch.update(customPermalinkDoc, {
        modeId: this.customLinkTransferModeId,
      });

      const currModeForEdit = this.currModeForEdit as Mode;
      const newPermalinks = {
        ...this.$store.state.currUser.permalinks,
        [this.customLinkTransferModeId]: this.permalink, // Transfer to new mode locally.
      };
      delete newPermalinks[currModeForEdit.docId]; // Delete old permalink local record.

      batchUpdateUserDoc(
        batch,
        {
          [`permalinks.${this.customLinkTransferModeId}`]: this.permalink,
          [`permalinks.${currModeForEdit.docId}`]: deleteField(),
        },
        {
          permalinks: newPermalinks,
        },
        onBatchFailArray
      );

      batch
        .commit()
        .then(() => {
          showSuccess(t.transferSuccessful);
          this.showTransferPermalinkDialog = false;
        })
        .catch(() => {
          onBatchFailArray.forEach((func: () => void) => func());
        });
    },
    onRouteChange(next: any) {
      hideLoading(true);
      if (this.formIsDirty && !this.saveIsHappening) {
        next(false);
        if (this.genericModeDataForm.type) {
          showConfirmation(t.unsavedChanges, () => {
            next();
          });
        }
      } else {
        // Navigate to next view
        next();
      }
    },
    onFormInteraction() {
      this.formIsDirty = true;
    },
  },
});
