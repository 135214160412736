
import Vue from 'vue';
import { modeFormMixin } from '@/mixins';
import { getEmptyBlogPostReference } from '@/constants';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { handleImages } from '@/util-functions/image-utils';
import SitchTextEditor from '@/components/custom-ui-components/SitchTextEditor.vue';
import { collection, doc, getDoc, getDocs, writeBatch } from 'firebase/firestore';
import { saveMode } from '@/util-functions/firestore-mode-utils';
import { currFirestore } from '@/util-functions/initialization-utils';
import { t } from '@/util-functions/language-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { getUserModeGatewayDoc } from '@/util-functions/user-utils';

const contentSubCollectionName = 'postContent';

export default Vue.extend({
  components: {
    SitchTextEditor,
  },
  mixins: [modeFormMixin],
  data(): {
    imageSizes: ImageSizes;
    modeForm: Omit<BlogMode, keyof Mode>;
    newContentMap: { [contentId: string]: string };
    goLiveDateMenu: Record<string, boolean>;
    goLiveTimeMenu: Record<string, boolean>;
  } {
    return {
      imageSizes: { small: 200, large: 400 },
      modeForm: {} as Omit<BlogMode, keyof Mode>,
      newContentMap: {},
      goLiveDateMenu: {},
      goLiveTimeMenu: {},
    };
  },
  methods: {
    wasJustCreated(postId: string) {
      return this.$store.state.idsGeneratedSinceLastModeFormPopulation.includes(postId);
    },
    fetchContent(postId: string) {
      // Get the mode objects based on ids
      getDoc(doc(currFirestore, getUserModeGatewayDoc().path, 'modes', this.$data.genericModeDataForm.docId, contentSubCollectionName, postId))
        .then((postContentDocSnap) => {
          if (postContentDocSnap.exists()) {
            this.newContentMap[postId] = (postContentDocSnap.data() as BlogPostContent).content;
            this.$forceUpdate();
          }
        })
        .catch((error: any) => {
          showError(`Cannot get post content.`, error, true);
        });
    },
    onAddPostReference() {
      const BLOG_MAX = 1000;
      if (this.modeForm.postReferences.length > BLOG_MAX) {
        showError(t?.blogItemMax.supplant([BLOG_MAX]));
        return;
      }
      this.modeForm.postReferences.push(getEmptyBlogPostReference());
    },
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      const mixinMethods = this as any as ModeMixinMethods;
      const postDeletionPreviewArray: BlogPostReference[] = mixinMethods.postDeletionPreviewArray(this.modeForm.postReferences);

      if (!postDeletionPreviewArray.length) {
        showError(t.mustHaveAtleastOneItem);
        return;
      }

      mixinMethods
        .pruneAndCommitDeletionsJustBeforeCreatingCombinedModeData()
        .then(() => {
          const promiseArray: Promise<any>[] = [];
          const contentIdsInUse = this.modeForm.postReferences.map((postReference) => postReference.id);
          const combinedModeData = getCombinedModeFormData(this) as BlogMode;
          const storagePath = `userFiles/${this.$store.state.userId}/${combinedModeData.docId}`;

          // Cycle through every gallery item in the form.
          combinedModeData.postReferences.forEach((item: BlogPostReference) => {
            handleImages(
              item,
              [
                {
                  key: 'images',
                  imageSizes: this.imageSizes,
                },
              ],
              promiseArray,
              storagePath
            );
          });

          Promise.all(promiseArray).then(() => {
            saveMode({ ...combinedModeData }).then((newlyCreatedOrUpdatedModeDoc) => {
              const contentCollectionRef = collection(currFirestore, newlyCreatedOrUpdatedModeDoc.path, contentSubCollectionName);
              getDocs(contentCollectionRef)
                .then((querySnapshot) => {
                  const contentUpdateBatch = writeBatch(currFirestore);

                  // Delete content for deleted blogs codes not in use.
                  querySnapshot.forEach((contentDocSnap) => {
                    if (!contentIdsInUse.includes(contentDocSnap.id)) {
                      contentUpdateBatch.delete(contentDocSnap.ref);
                    }
                  });

                  // Write the promo codes that are in use.
                  Object.entries(this.newContentMap).forEach(([contentId, content]) => {
                    contentUpdateBatch.set(doc(currFirestore, contentCollectionRef.path, contentId), { content });
                  });

                  contentUpdateBatch.commit().catch((error: any) => {
                    showError(`Error when saving blog.`, error, true);
                  });
                })
                .catch((error: any) => {
                  showError(`Could not get blog post collection.`, error, true);
                });
            });
          });
        })
        .catch(() => {
          showError(t.somethingWentWrongWhenDeletingAnItem);
        });
    },
  },
});
