
import Vue from 'vue';
import { modeFormMixin } from '@/mixins';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { saveMode } from '@/util-functions/firestore-mode-utils';

export default Vue.extend({
  mixins: [modeFormMixin],
  data(): {
    modeForm: Omit<WordleMode, keyof Mode>;
  } {
    return {
      modeForm: {} as Omit<WordleMode, keyof Mode>,
    };
  },
  methods: {
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      const combinedModeData = getCombinedModeFormData(this) as WordleMode;

      saveMode({ ...combinedModeData });
    },
  },
});
