
import Vue, { PropType } from 'vue';
import SitchTextEditor from '@/components/custom-ui-components/SitchTextEditor.vue';
import validationRules from '@/validation-rules';
import { t } from '@/util-functions/language-utils';
import { generateId } from '@/util-functions/misc-firestore-utils';

export default Vue.extend({
  components: {
    SitchTextEditor,
  },
  props: {
    value: {
      type: Object as PropType<ModifierGroup>,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data(): {
    rules: typeof validationRules;
    modifierGroup: ModifierGroup;
    canPickAsManyAsTheyWant: boolean;
  } {
    return {
      rules: validationRules,
      modifierGroup: this.value,
      canPickAsManyAsTheyWant: this.value.numberOfSelections === 0,
    };
  },
  watch: {
    value(v) {
      this.modifierGroup = v;
      this.canPickAsManyAsTheyWant = this.modifierGroup.numberOfSelections === 0;
      this.$forceUpdate();
    },
  },

  computed: {
    maxNumberOfSelections(): number {
      if (!this.modifierGroup.canSelectMultipleOfSameModifier) {
        return this.modifierGroup.modifiers.length;
      }
      return 50;
    },
    derivedModifierGroupSelectionParmatersExplained(): string {
      let ret = '';
      if (this.modifierGroup.numberOfSelections) {
        if (this.modifierGroup.canSelectMultipleOfSameModifier) {
          if (this.modifierGroup.mustPickExactAmount) {
            ret += t?.mustPickNotDistinct.supplant([this.modifierGroup.numberOfSelections]);
          } else {
            ret += t?.canPickNotDistinct.supplant([this.modifierGroup.numberOfSelections]);
          }
        } else {
          if (this.modifierGroup.mustPickExactAmount) {
            ret += t?.mustPickDistinct.supplant([this.modifierGroup.numberOfSelections]);
          } else {
            ret += t?.canPickDistinct.supplant([this.modifierGroup.numberOfSelections]);
          }
        }
      } else {
        if (this.modifierGroup.canSelectMultipleOfSameModifier) {
          ret += t?.canPickHoweverManyNotDistict;
        } else {
          ret += t?.canPickHoweverManyDistict;
        }
      }
      return ret;
    },
  },
  methods: {
    onModifierStockChange(modifier: Modifier, newStock: number) {
      modifier.isSoldOut = newStock <= 0;
    },
    onCanPickAsManyAsTheyWantToggle() {
      this.modifierGroup.numberOfSelections = this.canPickAsManyAsTheyWant ? 0 : 1;
    },
    onNumberOfSelectionsChange() {
      this.canPickAsManyAsTheyWant = this.modifierGroup.numberOfSelections === 0;
    },
    addModifier() {
      const newModifier: Modifier = {
        id: generateId(),
        name: '',
        amountChange: 0,
        isHidden: false,
        isSoldOut: false,
        hasStock: false,
        requiresCustomerInput: false,
        customerInputLabel: '',
        customerInput: '',
        stock: 0,
        quantitySelected: 0,
      };
      this.modifierGroup.modifiers.push(newModifier);

      this.onChange();
    },
    removeModifier(index: number) {
      this.modifierGroup.modifiers.splice(index, 1);
      this.onChange();
    },
    onChange() {
      this.capNumberOfSelectionsIfNeeded();
      this.$emit('input', this.modifierGroup);
    },
    capNumberOfSelectionsIfNeeded() {
      if (this.modifierGroup.numberOfSelections > this.maxNumberOfSelections) {
        this.modifierGroup.numberOfSelections = this.maxNumberOfSelections;
      }
    },
  },
});
