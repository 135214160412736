
import Vue from 'vue';
import stripeCurrencies from '@/stripe-currencies';
import { modeFormMixin } from '@/mixins';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { saveMode } from '@/util-functions/firestore-mode-utils';
import { onConnectStripeAccount } from '@/util-functions/stripe-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { t } from '@/util-functions/language-utils';

export default Vue.extend({
  mixins: [modeFormMixin],
  data(): {
    stripeCurrencies: string[];
    modeForm: Omit<PersonalPaymentsMode, keyof Mode>;
  } {
    return {
      stripeCurrencies,
      modeForm: {} as Omit<PersonalPaymentsMode, keyof Mode>,
    };
  },
  methods: {
    onConnectStripeAccount() {
      onConnectStripeAccount();
    },
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      if (!this.modeForm.paypalEnabled && !this.modeForm.venmoEnabled && !this.modeForm.cashAppEnabled && !this.modeForm.interacEnabled && !this.modeForm.bitcoinEnabled) {
        showError(t?.mustEnableAtLeastOnePaymentMethod);
        return;
      }

      if (this.modeForm.interacEnabled && !this.modeForm.interacEmail && !this.modeForm.interacPhoneNumber) {
        showError(t?.enterAnInteracPhoneNumberOrEmail);
        return;
      }

      const combinedModeData = getCombinedModeFormData(this) as PersonalPaymentsMode;
      saveMode({ ...combinedModeData });
    },
  },
});
