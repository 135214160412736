
import Vue from 'vue';
import { modeFormMixin } from '@/mixins';
import { getEmptyGalleryItem } from '@/constants';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { handleImages } from '@/util-functions/image-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { t } from '@/util-functions/language-utils';
import { saveMode } from '@/util-functions/firestore-mode-utils';
import SitchTextEditor from '@/components/custom-ui-components/SitchTextEditor.vue';

export default Vue.extend({
  mixins: [modeFormMixin],
  components: {
    SitchTextEditor,
  },
  data(): {
    imageSizes: ImageSizes;
    modeForm: Omit<GalleryMode, keyof Mode>;
  } {
    return {
      imageSizes: { small: 400, large: 1080 },
      modeForm: {} as Omit<GalleryMode, keyof Mode>,
    };
  },
  methods: {
    onAddGalleryItem() {
      // This limit is to prevent nefarious use.
      const GALLERY_ITEM_MAX = 100;
      if (this.modeForm.galleryItems.length > GALLERY_ITEM_MAX) {
        showError(t?.galleryItemMax.supplant([GALLERY_ITEM_MAX]));
        return;
      }
      this.modeForm.galleryItems.push(getEmptyGalleryItem());
    },
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      const mixinMethods = this as any as ModeMixinMethods;
      const postDeletionPreviewArray = mixinMethods.postDeletionPreviewArray(this.modeForm.galleryItems);

      if (!postDeletionPreviewArray.length) {
        showError(t.mustHaveAtleastOneItem);
        return;
      }

      let galleryItemWithoutAnImage = '';

      postDeletionPreviewArray.some((galleryItem) => {
        const isGalleryItemWithoutAnImage = !galleryItem.images.length || galleryItem.images[0].markedForDeletion;
        if (isGalleryItemWithoutAnImage) {
          galleryItemWithoutAnImage = galleryItem.name;
          showError(t.mustUploadAtLeastOneImage);
        }
        return isGalleryItemWithoutAnImage;
      });

      if (galleryItemWithoutAnImage) {
        showError(t.allGalleryItemsMustHaveAnImage.supplant([galleryItemWithoutAnImage]));
        return;
      }

      mixinMethods
        .pruneAndCommitDeletionsJustBeforeCreatingCombinedModeData()
        .then(() => {
          const promiseArray: Promise<any>[] = [];
          const combinedModeData = getCombinedModeFormData(this) as GalleryMode;
          const storagePath = `userFiles/${this.$store.state.userId}/${combinedModeData.docId}`;

          // Cycle through every gallery item in the form.
          combinedModeData.galleryItems.forEach((item: GalleryItem) => {
            handleImages(
              item,
              [
                {
                  key: 'images',
                  imageSizes: this.imageSizes,
                },
              ],
              promiseArray,
              storagePath
            );
          });

          Promise.all(promiseArray).then(() => {
            saveMode({ ...combinedModeData });
          });
        })
        .catch(() => {
          showError(t.somethingWentWrongWhenDeletingAnItem);
        });
    },
  },
});
