
import Vue from 'vue';
import stripeCurrencies from '@/stripe-currencies';
import { modeFormMixin } from '@/mixins';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { eModeType } from '@/enums';
import { onConnectStripeAccount } from '@/util-functions/stripe-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { t } from '@/util-functions/language-utils';
import { saveMode } from '@/util-functions/firestore-mode-utils';

export default Vue.extend({
  mixins: [modeFormMixin],
  data(): {
    stripeCurrencies: string[];
    modeForm: Omit<BusinessPaymentsMode, keyof Mode>;
  } {
    return {
      stripeCurrencies,
      modeForm: {} as Omit<BusinessPaymentsMode, keyof Mode>,
    };
  },
  computed: {
    stripeAccounts(): StripeAccount[] {
      return this.$store.getters.stripeAccounts;
    },
    customFormModesArray(): { docId: string; name: string }[] {
      const modes = Object.values(this.$store.state.modes) as AnyMode[];
      return [{ docId: '', name: t.none }, ...modes.filter((mode: AnyMode) => mode.type === eModeType.customForm)];
    },
  },
  watch: {
    modeForm() {
      this.onLoad();
    },
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.checkForDeletedCustomForms();
    },
    checkForDeletedCustomForms() {
      const preSubmissionSelectedCustomForm = this.customFormModesArray.find((mode) => mode.docId === this.modeForm.preSubmissionCustomFormModeId);
      const postSubmissionSelectedCustomForm = this.customFormModesArray.find((mode) => mode.docId === this.modeForm.postSubmissionCustomFormModeId);

      // If the custom form we were using is deleted, reset this.
      if (!preSubmissionSelectedCustomForm) {
        this.modeForm.preSubmissionCustomFormModeId = '';
      }
      if (!postSubmissionSelectedCustomForm) {
        this.modeForm.preSubmissionCustomFormModeId = '';
      }
    },
    onConnectStripeAccount() {
      onConnectStripeAccount();
    },
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }
      if (!this.modeForm.stripeAccountId) {
        showError(t.validStripeAccountRequired);
        return;
      }
      const combinedModeData = getCombinedModeFormData(this) as BusinessPaymentsMode;
      saveMode({ ...combinedModeData });
    },
  },
});
