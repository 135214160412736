export default [
  { text: 'Afrikaans', value: 'af' },
  { text: 'Albanian', value: 'sq' },
  { text: 'Amharic', value: 'am' },
  { text: 'Arabic', value: 'ar' },
  { text: 'Armenian', value: 'hy' },
  { text: 'Azerbaijani', value: 'az' },
  { text: 'Basque', value: 'eu' },
  { text: 'Belarusian', value: 'be' },
  { text: 'Bengali', value: 'bn' },
  { text: 'Bosnian', value: 'bs' },
  { text: 'Bulgarian', value: 'bg' },
  { text: 'Catalan', value: 'ca' },
  { text: 'Cebuano', value: 'ceb' },
  { text: 'Chinese (Simplified)', value: 'zh-CN' },
  { text: 'Chinese (Traditional)', value: 'zh-TW' },
  { text: 'Corsican', value: 'co' },
  { text: 'Croatian', value: 'hr' },
  { text: 'Czech', value: 'cs' },
  { text: 'Danish', value: 'da' },
  { text: 'Dutch', value: 'nl' },
  { text: 'English', value: 'en' },
  { text: 'Esperanto', value: 'eo' },
  { text: 'Estonian', value: 'et' },
  { text: 'Finnish', value: 'fi' },
  { text: 'French', value: 'fr' },
  { text: 'Frisian', value: 'fy' },
  { text: 'Galician', value: 'gl' },
  { text: 'Georgian', value: 'ka' },
  { text: 'German', value: 'de' },
  { text: 'Greek', value: 'el' },
  { text: 'Gujarati', value: 'gu' },
  { text: 'Haitian Creole', value: 'ht' },
  { text: 'Hausa', value: 'ha' },
  { text: 'Hawaiian', value: 'haw' },
  { text: 'Hebrew', value: 'he' },
  { text: 'Hindi', value: 'hi' },
  { text: 'Hmong', value: 'hmn' },
  { text: 'Hungarian', value: 'hu' },
  { text: 'Icelandic', value: 'is' },
  { text: 'Igbo', value: 'ig' },
  { text: 'Indonesian', value: 'id' },
  { text: 'Irish', value: 'ga' },
  { text: 'Italian', value: 'it' },
  { text: 'Japanese', value: 'ja' },
  { text: 'Javanese', value: 'jv' },
  { text: 'Kannada', value: 'kn' },
  { text: 'Kazakh', value: 'kk' },
  { text: 'Khmer', value: 'km' },
  { text: 'Kinyarwanda', value: 'rw' },
  { text: 'Korean', value: 'ko' },
  { text: 'Kurdish', value: 'ku' },
  { text: 'Kyrgyz', value: 'ky' },
  { text: 'Lao', value: 'lo' },
  { text: 'Latin', value: 'la' },
  { text: 'Latvian', value: 'lv' },
  { text: 'Lithuanian', value: 'lt' },
  { text: 'Luxembourgish', value: 'lb' },
  { text: 'Macedonian', value: 'mk' },
  { text: 'Malagasy', value: 'mg' },
  { text: 'Malay', value: 'ms' },
  { text: 'Malayalam', value: 'ml' },
  { text: 'Maltese', value: 'mt' },
  { text: 'Maori', value: 'mi' },
  { text: 'Marathi', value: 'mr' },
  { text: 'Mongolian', value: 'mn' },
  { text: 'Myanmar (Burmese)', value: 'my' },
  { text: 'Nepali', value: 'ne' },
  { text: 'Norwegian', value: 'no' },
  { text: 'Nyanja (Chichewa)', value: 'ny' },
  { text: 'Odia (Oriya)', value: 'or' },
  { text: 'Pashto', value: 'ps' },
  { text: 'Persian', value: 'fa' },
  { text: 'Polish', value: 'pl' },
  { text: 'Portuguese (Portugal, Brazil)', value: 'pt' },
  { text: 'Punjabi', value: 'pa' },
  { text: 'Romanian', value: 'ro' },
  { text: 'Russian', value: 'ru' },
  { text: 'Samoan', value: 'sm' },
  { text: 'Scots Gaelic', value: 'gd' },
  { text: 'Serbian', value: 'sr' },
  { text: 'Sesotho', value: 'st' },
  { text: 'Shona', value: 'sn' },
  { text: 'Sindhi', value: 'sd' },
  { text: 'Sinhala (Sinhalese)', value: 'si' },
  { text: 'Slovak', value: 'sk' },
  { text: 'Slovenian', value: 'sl' },
  { text: 'Somali', value: 'so' },
  { text: 'Spanish', value: 'es' },
  { text: 'Sundanese', value: 'su' },
  { text: 'Swahili', value: 'sw' },
  { text: 'Swedish', value: 'sv' },
  { text: 'Tagalog (Filipino)', value: 'tl' },
  { text: 'Tajik', value: 'tg' },
  { text: 'Tamil', value: 'ta' },
  { text: 'Tatar', value: 'tt' },
  { text: 'Telugu', value: 'te' },
  { text: 'Thai', value: 'th' },
  { text: 'Turkish', value: 'tr' },
  { text: 'Turkmen', value: 'tk' },
  { text: 'Ukrainian', value: 'uk' },
  { text: 'Urdu', value: 'ur' },
  { text: 'Uyghur', value: 'ug' },
  { text: 'Uzbek', value: 'uz' },
  { text: 'Vietnamese', value: 'vi' },
  { text: 'Welsh', value: 'cy' },
  { text: 'Xhosa', value: 'xh' },
  { text: 'Yiddish', value: 'yi' },
  { text: 'Yoruba', value: 'yo' },
  { text: 'Zulu', value: 'zu' },
];
