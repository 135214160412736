
import Vue from 'vue';
import { modeFormMixin } from '@/mixins';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import { SMALL_FILE_IMAGE_MAX_DIMENSION, LARGE_FILE_IMAGE_MAX_DIMENSION } from '@/constants';
import { getDownloadURL, ref, uploadBytesResumable, UploadMetadata } from 'firebase/storage';
import { saveMode } from '@/util-functions/firestore-mode-utils';
import { compressImage } from '@/util-functions/image-utils';
import { t } from '@/util-functions/language-utils';
import { showLoading, hideLoading } from '@/util-functions/loading-utils';
import { generateId, showError } from '@/util-functions/misc-firestore-utils';
import { isValidUpload, trackUploadProgress, stopTrackingUploadProgress } from '@/util-functions/storage-utils';
import { fbStorage } from '@/util-functions/initialization-utils';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraResultType } from '@capacitor/camera';

export default Vue.extend({
  mixins: [modeFormMixin],
  data(): {
    compressedUploadedFiles: CompressedFile[];
    showCtaButtonOptions: boolean;
    isForSitchCam: boolean;
    modeForm: Omit<FilesMode, keyof Mode>;
    isNativePlatform: boolean;
  } {
    return {
      compressedUploadedFiles: [],
      showCtaButtonOptions: false,
      isForSitchCam: this.$parent?.$props.isForSitchCam || false,
      modeForm: {} as Omit<FilesMode, keyof Mode>,
      isNativePlatform: Capacitor.isNativePlatform(),
    };
  },
  computed: {
    input(): HTMLInputElement {
      let input: HTMLInputElement = this.$refs.filesInputAllFiles as HTMLInputElement;
      if (this.isForSitchCam) {
        input = this.$refs.filesInputSitchCam as HTMLInputElement;
      }
      return input;
    },
  },
  methods: {
    onUploadClick() {
      if (!this.isNativePlatform) {
        this.input.click();
      } else {
        Camera.getPhoto({
          quality: 90,
          allowEditing: false,
          resultType: CameraResultType.Uri,
        }).then((image) => {
          // image.webPath will contain a path that can be set as an image src.
          // You can access the original file using image.path, which can be
          // passed to the Filesystem API to read the raw data of the image,
          // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
          if (!image.webPath) {
            return;
          }
          fetch(image.webPath)
            .then((res) => {
              return res.arrayBuffer();
            })
            .then((buf) => {
              const file = new File([buf], `pic-${generateId().slice(0, 5)}`, { type: 'image/jpg' });
              this.uploadProvidedFiles([file]);
            });
        });
      }
    },
    onUploadFilesForSitchCam() {
      this.onUploadFiles(`pic-${generateId().slice(0, 5)}`);
    },
    onUploadFilesRegular() {
      this.onUploadFiles();
    },
    onUploadFiles(sitchCamPicfileName = '') {
      let newUploadedFiles = Array.from(this.input.files || []);
      if (this.input.files) {
        this.input.value = '';
      }
      this.uploadProvidedFiles(newUploadedFiles, sitchCamPicfileName);
    },
    uploadProvidedFiles(newUploadedFiles: File[], sitchCamPicfileName = '') {      
      const FILES_MAX = 50;
      if (newUploadedFiles.length + this.modeForm.files.length > FILES_MAX) {
        showError(t?.filesMax.supplant([FILES_MAX]));
        return;
      }      

      if (newUploadedFiles.length) {
        showLoading();
        const compressedUploadedFileBatch: CompressedFile[] = [];
        const promiseArray: Promise<void>[] = [];
        newUploadedFiles.forEach((file) => {
          if (file.type.includes('image')) {
            promiseArray.push(
              new Promise((resolve, reject) => {
                compressImage(file, SMALL_FILE_IMAGE_MAX_DIMENSION)
                  .then((imagePreviewFileObj) => {
                    compressImage(file, LARGE_FILE_IMAGE_MAX_DIMENSION)
                      .then((compressedFileObj) => {
                        if (!isValidUpload(compressedFileObj.compressedFile.size)) {
                          reject();
                          return;
                        }
                        compressedUploadedFileBatch.push({
                          id: generateId(),
                          file: compressedFileObj.compressedFile,
                          fileName: sitchCamPicfileName || compressedFileObj.compressedFile.name,
                          description: '',
                          mimeType: compressedFileObj.compressedFile.type,
                          base64ImagePreview: imagePreviewFileObj.base64data,
                        });
                        hideLoading();
                        resolve();
                      })
                      .catch((error: any) => {
                        reject();
                        showError(`Main image compression failed.`, error, true);
                        hideLoading();
                      });
                  })
                  .catch((error: any) => {
                    reject();
                    showError(`Preview image compression failed.`, error, true);
                    hideLoading();
                  });
              })
            );
          } else {
            hideLoading();
            if (!isValidUpload(file.size)) {              
              return;
            }            
            compressedUploadedFileBatch.push({
              id: generateId(),
              file,
              fileName: file.name,
              description: '',
              mimeType: file.type,
            });
          }
        });
        Promise.all(promiseArray).then(() => {
          this.compressedUploadedFiles = [...this.compressedUploadedFiles, ...compressedUploadedFileBatch];
        });
      }
    },
    isImageMimeType(mimeType: string): boolean {
      return mimeType.includes('image/');
    },
    getFileIcon(mimeType: string): string {
      // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
      const iconClasses: any = {
        // Media
        image: 'file-image',
        audio: 'file-audio',
        video: 'file-video',
        // Documents
        'application/pdf': 'file-pdf',
        'application/msword': 'file-word',
        'application/vnd.ms-word': 'file-word',
        'application/vnd.oasis.opendocument.text': 'file-word',
        'application/vnd.openxmlformats-officedocument.wordprocessingml': 'file-word',
        'application/vnd.ms-excel': 'file-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml': 'file-excel',
        'application/vnd.oasis.opendocument.spreadsheet': 'file-excel',
        'application/vnd.ms-powerpoint': 'file-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml': 'file-powerpoint',
        'application/vnd.oasis.opendocument.presentation': 'file-powerpoint',
        'text/plain': 'file-alt',
        'text/x-java-source,java': 'file-code',
        'application/javascript': 'file-code',
        'application/json': 'file-code',
        // Archives
        'application/gzip': 'file-archive',
        'application/zip': 'file-archive',
      };

      for (const key in iconClasses) {
        if (iconClasses[key]) {
          if (mimeType.search(key) === 0) {
            // Found it
            return iconClasses[key];
          }
        }
      }
      return 'file';
    },
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      const mixinMethods = this as any as ModeMixinMethods;
      const postDeletionPreviewArray: StorageFile[] = mixinMethods.postDeletionPreviewArray(this.modeForm.files);

      if (!postDeletionPreviewArray.length && !this.compressedUploadedFiles.length) {
        if (this.$parent?.$data.currModeForEdit) {
          showError(t.mustContainAtLeastOneFileForEdit);
        } else {
          showError(t.mustContainAtLeastOneFile);
        }
        return;
      }

      mixinMethods
        .pruneAndCommitDeletionsJustBeforeCreatingCombinedModeData()
        .then(() => {
          const combinedModeData: FilesMode = getCombinedModeFormData(this);
          const storagePath = `userFiles/${this.$store.state.userId}/${combinedModeData.docId}`;

          showLoading();

          if (this.compressedUploadedFiles.length) {
            const oldFilesArray = combinedModeData.files || [];
            const storageFileArray: StorageFile[] = [...oldFilesArray];
            const promiseArray: Promise<any>[] = [];
            this.compressedUploadedFiles.forEach((compressedFile: CompressedFile) => {
              const file = compressedFile.file;
              const fileSize = file.size;
              const fullPath = `${storagePath}/${compressedFile.id}_${compressedFile.fileName || ''}`;
              const metadata: UploadMetadata = {
                customMetadata: {
                  fullPath,
                  userId: this.$store.state.userId,
                },
              };

              if (isValidUpload(fileSize)) {
                promiseArray.push(
                  new Promise((resolve, reject) => {
                    const uploadTask = uploadBytesResumable(ref(fbStorage, fullPath), file, metadata);

                    const uploadTaskId = generateId();
                    trackUploadProgress(uploadTask, uploadTaskId);

                    uploadTask.then((uploadTaskSnapshot) => {
                      getDownloadURL(uploadTaskSnapshot.ref)
                        .then((downloadUrl: string) => {
                          stopTrackingUploadProgress(uploadTaskId);
                          // Push the uploaded image into data.
                          storageFileArray.push({
                            id: compressedFile.id,
                            mimeType: compressedFile.file.type,
                            fileName: compressedFile.fileName,
                            description: compressedFile.description,
                            storagePath,
                            downloadUrl,
                            base64ImagePreview: compressedFile.base64ImagePreview || '',
                            isHidden: false,
                          });
                          this.$store.commit('storageQuota', this.$store.state.storageQuota + fileSize);
                          resolve(true);
                        })
                        .catch((error: any) => {
                          showError(`Unable to get the download URL for ${storagePath}.`, error, true);
                          hideLoading();
                          reject(false);
                        });
                    });
                  })
                );
              }
            });
            Promise.all(promiseArray).then(() => {
              combinedModeData.files = storageFileArray;
              saveMode({ ...combinedModeData });
            });
          } else {
            saveMode({ ...combinedModeData });
          }
        })
        .catch(() => {
          showError(t.somethingWentWrongWhenDeletingAnItem);
        });
    },
  },
});
